<template>
	<div>
		<div class="bg-light box-shadow-base pd-10" v-if="data">
			<div class="lh-40 f-14 bd-b-w-1 bd-dark-7 bd-style-solid">数据平台行业数据分析报告—医疗器械第三方物流</div>
			<div class="mg-t-10 f-14 dark-3">根据国家药监局发布的数据显示，{{nowYear}}年我国医疗器械第三方物流企业数量为{{data.now.total_income}}家，我国医疗器械第三方物流企业数量逐年下降，说明随着医疗器械第三方物流行业集中度不断提升。</div>
		</div>
		<div class="mg-t-30 bg-light box-shadow-base pd-30">
			<lee-bar-echarts
				ref="bar5"
				:grid-bottom="30"
				height="200px"
				:show-legend="true"
				:show-left="true"
				:show-right="false"></lee-bar-echarts>
		</div>
	</div>
</template>

<script>
export default {
	name: "IndexHyData7",
	data() {
		return {
			data: null,
			nowYear: '',
			endYear: ''
		}
	},
	props: {
		// year:{
		//     type:[String,Number],
		//     default:''
		// },
		type: {
			type: String,
			default: ''
		}
	},
	created() {

	},
	mounted() {
		this.getYears();
	},
	methods: {
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/Hydata/getQixieYears',
				data: null,
				success(res) {
					_this.endYear = res[res.length - 1];

					_this.nowYear = res[0];

					_this.getTotal1(_this.nowYear, _this.endYear);
				}
			});
		},
		getTotal1(startYear, endYear) {
			let _this = this;

			if (this.type) {
				this.$http.post({
					url: '/Hydata/getTypeData',
					data: {year: startYear, type: this.type, yearlist: [startYear, endYear]},
					success(res) {
						_this.data = res;

						let _bar5 = {bar: [], xaxis: []};

						let _dsfwlNow = res.now;
						let _dsfwlList = res.list;

						if (_dsfwlList && _dsfwlList.length > 0) {
							for(let i=0;i < _dsfwlList.length;i++){
								console.log(_dsfwlList[i].total_income)
								_bar5.bar.push(parseFloat(_dsfwlList[i].total_income));
								_bar5.xaxis.push(_dsfwlList[i].year+'年');
							}
						}

						setTimeout(() => {
							_this.$nextTick(() => {
								if(_dsfwlList && _dsfwlList.length > 0) {
									if (_this.$refs.bar5) {
										_this.$refs.bar5.initData(
											[
												{name: '企业数量', type: 'bar', color: 'blue', data: _bar5.bar, dw: '家'},
											],
											_bar5.xaxis
										);
									}
								}
							})
						}, 300);
					}
				})
			}
		},
	}
}
</script>
