<template>
	<lee-page bg-img="/images/bg_head_4.jpg" title="行业数据" sub-title="MEDICAL DATA" :height="250" title-class="margin-top-90" :bread="[{title:'行业数据',url:'/hydata/ylqxwlcl'},{title:'物流仓储'}]">
		<div class="bg-img-11 padding-top-bottom-50 padding-top-20" :style="{backgroundColor:'#f8f9fa'}">
			<div class="wrap1100">
				<div class="text-center" style="margin-left: -10px;margin-right: -10px;">
					<span class="margin-bottom-20 padding-left padding-right inline-block" style="width:20%;padding-left:10px;padding-right:10px;" v-for="(item,index) in tabList" @click="onChangeDataTab(item.type)">
							<div :class="active === item.type ? 'bg-blue light' : 'dark-1'" class="padding-top-bottom box-shadow-blue-small border-radius-10 f14 hand" style="height: 44px;overflow: hidden;">
								<span style="line-height: 24px;">{{item.title}}</span>
							</div>
					</span>
				</div>
			</div>
			<check-vip akey="hydata-qixie-ylqxwlcl">
				<div class="wrap1100 padding-bottom-100" v-if="data">
					<lee-year-tab :list="yearList"></lee-year-tab>
					<div class="box-shadow padding-30 bg-light margin-top-20">
						<div class="cl f34">
							<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#00A3DC" class="z margin-right"></lee-img-icon>
							<div class="z f22">行业数据 - 物流仓储
							</div>
						</div>
						<div class="dark-2 f16" v-if="data && data.ylqxsc && data.ylqxsc.now">中物联医疗器械供应链分会经公开数据整理，不完全统计推算，截止{{nowYear}}年底，我国医疗器械物流仓储总面积约为{{data.ylqxwlcl.now.total_income}}万平方米。其中，在各种类仓库中，{{data.ylqxwlcl.now.values[0].name}}占比最大，为{{ (data.ylqxwlcl.now.values[0].val.income_percent * 100).toFixed(2)}}%。{{data.ylqxwlcl.now.values[1].name}}和{{data.ylqxwlcl.now.values[2].name}}面积占比分别为{{ (data.ylqxwlcl.now.values[1].val.income_percent * 100).toFixed(2)}}%和{{ (data.ylqxwlcl.now.values[2].val.income_percent * 100).toFixed(2)}}%。</div>
						<div class="margin-auto" style="width: 100%">
							<lee-pie-echarts
								ref="pie1"
								:grid-bottom="30"
								height="350px"
								:show-legend="false"
								label-format="{b}:{d}%"
								label-pos="outside"
								:radius="['0%','50%']"></lee-pie-echarts>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "ylqxsc2",
	data() {
		return {
			yearList: [],
			nowYear: '',

			startYear: '',
			endYear: '',

			yearRange: [],

			tabList: [
				{title: '医疗设备全周期服务管理', link: '#a1', type: 'life', img: 'icon_9.png'},
				{title: '医疗器械SPD', link: '#a2', type: 'spd', img: 'icon_10.png'},
				{title: '医疗器械第三方物流', link: '#a3', type: 'dsfwl', img: 'icon_11.png'},
				{title: '市场规模', link: '#a4', type: 'ylqxsc', img: 'icon_11.png'},
				{title: '物流总费用', link: '#a5', type: 'ylqxsc2', img: 'icon_11.png'},
				{title: '物流仓储', link: '#a6', type: 'ylqxwlcl', img: 'icon_11.png'},
				{title: '物流运输车辆', link: '#a7', type: 'ylqxwlcl2', img: 'icon_11.png'},
			],

			title: '',

			data:null,

			active: 'ylqxwlcl',
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeDataTab(e) {
			this.$router.push({path: '/hydata/' + e});
		},
		changeTab(v) {
			this.nowYear = v.value;
			this.getAll(this.nowYear);
		},
		getAll(year) {
			for (let i in this) {
				if (i.indexOf('getTotal') === 0) {
					this[i](year);
				}
			}
		},

		findYear(year, list) {
			if (list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].year === year) {
						return list[i];
					}
				}
				return false;
			}
			return false;
		},

		getYears() {
			let _this = this;

			let _type = '';

			if(this.active === 'ylqxsc2'){
				_type = 'ylqxsc';
			}else if(this.active === 'ylqxwlcl2'){
				_type = 'ylqxwlcl';
			}else{
				_type = this.active
			}

			this.$http.post({
				url: '/Hydata/getQixieYears',
				data: {type: _type},
				success(res) {
					for (let i = 0; i < res.length; i++) {
						_this.yearList.push({label: res[i] + '年', value: res[i]});
					}

					_this.endYear = res[res.length - 1];

					_this.nowYear = _this.$route.query.year ? _this.$route.query.year : res[0];

					_this.getTotal1(_this.nowYear);
				}
			});
		},

		getTotal1(year) {
			let _this = this;
			this.$http.post({
				url: '/Hydata/getQixieData',
				data: {year: year, yearlist: [year, year - 4]},
				success(res) {
					_this.data = res;

					let _bar1 = {bar:[],xaxis:[]};
					let _bar2 = {bar:[],xaxis:[]};
					let _bar4 = {bar:[],xaxis:[]};
					let _bar5 = {bar:[],xaxis:[]};


					let _list1 = res.ylqxsc.list;
					let _list2 = res.ylqxsc.now;

					for(let i=0;i < _list1.length;i++) {
						_bar1.bar.push(_list1[i].total_income);
						_bar1.xaxis.push(_list1[i].year.toString());

						_bar2.bar.push(_list1[i].total_profit);
						_bar2.xaxis.push(_list1[i].year.toString());
					}

					for(let i=0;i < _list2.values.length;i++) {
						_bar4.bar.push(_list2.values[i].val.profit);
						_bar4.xaxis.push(_list2.values[i].name);

						_bar5.bar.push(_list2.values[i].val.income);
						_bar5.xaxis.push(_list2.values[i].name);
					}

					//pie1  pie2
					let _pie1 = [];
					let _pie2 = [];
					let _bar3 = {bar:[],bar2:[],xaxis:[]};

					let _pie1list = res.ylqxwlcl.now;
					let _pie1list2 = res.ylqxwlcl.list;

					for (let i=0;i< _pie1list.values.length;i++){
						_pie1.push({value:_pie1list.values[i].val.income,name:_pie1list.values[i].name});
					}

					for (let i=0;i< _pie1list.sub_values.length;i++){
						_pie2.push({value:_pie1list.sub_values[i].val.income,name:_pie1list.sub_values[i].name});
					}

					for (let i=0;i < _pie1list2.length;i++){
						_bar3.bar.push(parseInt(_pie1list2[i].sub_values[0].val.income));
						_bar3.bar2.push(parseInt(_pie1list2[i].sub_values[1].val.income));
						_bar3.xaxis.push(_pie1list2[i].year.toString());
					}


					setTimeout(()=>{
						if(_this.$refs.bar4) {
							_this.$refs.bar4.initData(
								[
									{name: '物流费用', type: 'bar', color: 'most', data: _bar4.bar,dw:'亿元'},
								],
								_bar4.xaxis
							);
						}

						if(_this.$refs.bar5) {
							_this.$refs.bar5.initData(
								[
									{name: '市场规模', type: 'bar', color: 'most', data: _bar5.bar,dw:'亿元'},
								],
								_bar5.xaxis
							);
						}

						if(_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '市场规模', type: 'bar', color: 'blue', data: _bar1.bar,dw:'亿元'},
								],
								_bar1.xaxis
							);
						}

						if(_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '物流费用', type: 'bar', color: 'blue', data: _bar2.bar,dw:'亿元'},
								],
								_bar2.xaxis
							);
						}

						if(_this.$refs.pie1){
							_this.$refs.pie1.initData(_pie1,'万平方米');
						}

						if(_this.$refs.pie2){
							_this.$refs.pie2.initData(_pie2,'辆');
						}

						if(_this.$refs.bar3) {
							_this.$refs.bar3.initData(
								[
									{name: '自有车辆', type: 'bar', color: 'blue', data: _bar3.bar,dw:'辆'},
									{name: '自有冷藏车车辆', type: 'bar', color: 'green', data: _bar3.bar2,dw:'辆'},
								],
								_bar3.xaxis
							);
						}
					},300)
				}
			});
		},
	}
}
</script>
