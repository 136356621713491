<template>
	<div>
		<div class="bg-light box-shadow-base pd-10" v-if="data">
			<div class="lh-40 f-14 bd-b-w-1 bd-dark-7 bd-style-solid">数据平台行业数据分析报告—医学装备全生命周期管理</div>
			<div class="mg-t-10 f-14 dark-3">截至{{nowYear}}年，中国在使用的医疗器械共计{{data.now.total_income + data.now.total_profit}}亿元。据分会不完全统计，其中耗材类医疗器械占{{data.now.profit_rate}}%，非耗材类医疗器械占{{data.now.income_rate}}%，也就是说医学装备全生命周期管理服务的在用医学装备市场规模为{{data.now.total_income}}亿元。在{{data.now.total_income}}亿元的市场中，{{data.now.values}}。基于此数据，据分会推算，{{nowYear}}年医学装备管理维修市场规模为1000亿元。</div>
		</div>
		<div class="mg-t-30 bg-light box-shadow-base pd-30">
			<lee-pie-echarts
				ref="pie1"
				:grid-bottom="30"
				height="200px"
				:show-legend="false"
				label-format="{b}:{d}%"
				label-pos="outside"
				:radius="['50%','80%']"></lee-pie-echarts>
		</div>
	</div>
</template>

<script>
export default {
	name: "IndexHyData7",
	data() {
		return {
			data: null,
			nowYear: '',
			endYear: ''
		}
	},
	props: {
		// year:{
		//     type:[String,Number],
		//     default:''
		// },
		type: {
			type: String,
			default: ''
		}
	},
	created() {

	},
	mounted() {
		this.getYears();
	},
	methods: {
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/Hydata/getQixieYears',
				data: null,
				success(res) {
					_this.endYear = res[res.length - 1];

					_this.nowYear = res[0];

					_this.getTotal1(_this.nowYear, _this.endYear);
				}
			});
		},
		getTotal1(startYear, endYear) {
			let _this = this;

			if (this.type) {
				this.$http.post({
					url: '/Hydata/getTypeData',
					data: {year: startYear, type: this.type, yearlist: [startYear, endYear]},
					success(res) {
						_this.data = res;

						let _pie1 = [];

						let _lifeNow = res.now;

						if (_lifeNow) {
							_pie1.push({name: '非耗材类医疗器械', value: _lifeNow.income_rate});
							_pie1.push({name: '耗材类医疗器械', value: _lifeNow.profit_rate});
						}

						setTimeout(() => {
							_this.$nextTick(() => {

								//全生命周期
								if (_lifeNow) {
									if (_this.$refs.pie1) {
										_this.$refs.pie1.initData(_pie1, '亿元');
									}
								}
							})
						}, 300);
					}
				})
			}
		},
	}
}
</script>
