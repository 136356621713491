<template>
	<lee-page bg-img="/images/bg_head_4.jpg" title="行业数据" sub-title="MEDICAL DATA" :height="250" title-class="margin-top-90" :bread="[{title:'行业数据',url:'/hydata/life'},{title:'医疗装备全周期服务管理'}]">
		<div class="bg-img-11 padding-top-bottom-50 padding-top-20" :style="{backgroundColor:'#f8f9fa'}">
			<div class="wrap1100">
				<div class="text-center" style="margin-left: -10px;margin-right: -10px;">
					<span class="margin-bottom-20 padding-left padding-right inline-block" style="width:20%;padding-left:10px;padding-right:10px;" v-for="(item,index) in tabList" @click="onChangeDataTab(item.type)">
							<div :class="active === item.type ? 'bg-blue light' : 'dark-1'" class="padding-top-bottom box-shadow-blue-small border-radius-10 f14 hand" style="height: 44px;overflow: hidden;">
<!--								<span class="vm bg-light border-radius text-center inline-block" style="width:24px;height:24px;">-->
<!--										<img :src="'/images/icon/'+item.img" width="18" height="18" style="margin-top:4px;"/>-->
<!--								</span>-->
								<span style="line-height: 24px;">{{item.title}}</span>
							</div>
					</span>
				</div>
			</div>
			<check-vip akey="hydata-qixie-life">
				<div class="wrap1100 padding-bottom-100" v-if="info">
					<lee-year-tab :list="yearList"></lee-year-tab>
					<div class="box-shadow padding-30 bg-light margin-top-20">
						<div class="cl f34">
							<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#00A3DC" class="z margin-right"></lee-img-icon>
							<div class="z f22">行业数据 - 医疗装备全周期服务管理
							</div>
						</div>
						<div class="f16 dark-2">
							截至{{nowYear}}年，中国在使用的医疗器械共计{{info.life.now.total_income + info.life.now.total_profit}}亿元。据分会不完全统计，其中耗材类医疗器械占{{info.life.now.profit_rate}}%，非耗材类医疗器械占{{info.life.now.income_rate}}%，也就是说医学装备全生命周期管理服务的在用医学装备市场规模为{{info.life.now.total_income}}亿元。在{{info.life.now.total_income}}亿元的市场中，{{info.life.now.values}}。基于此数据，据分会推算，{{nowYear}}年医学装备管理维修市场规模为1000亿元。
						</div>
						<div>
							<lee-pie-echarts
								ref="pie1"
								:grid-bottom="30"
								height="350px"
								:show-legend="false"
								label-format="{b}:{d}%"
								label-pos="outside"
								:radius="['50%','80%']"></lee-pie-echarts>
						</div>
						<div class="f16 dark-2 pd-t-50">
							截至{{nowYear}}年，国内共有{{info.life.now.area_total}}家医学装备维修企业。
						</div>
						<div>
							<lee-bar-echarts
								ref="bar1"
								:grid-bottom="30"
								height="350px"
								:show-legend="true"
								:show-left="true"
								:show-right="false"
							></lee-bar-echarts>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "life",
	data() {
		return {
			yearList: [],
			nowYear: '',

			startYear: '',
			endYear: '',

			yearRange: [],

			tabList: [
				{title: '医疗设备全周期服务管理', link: '#a1', type: 'life', img: 'icon_9.png'},
				{title: '医疗器械SPD', link: '#a2', type: 'spd', img: 'icon_10.png'},
				{title: '医疗器械第三方物流', link: '#a3', type: 'dsfwl', img: 'icon_11.png'},
				{title: '市场规模', link: '#a4', type: 'ylqxsc', img: 'icon_11.png'},
				{title: '物流总费用', link: '#a5', type: 'ylqxsc2', img: 'icon_11.png'},
				{title: '物流仓储', link: '#a6', type: 'ylqxwlcl', img: 'icon_11.png'},
				{title: '物流运输车辆', link: '#a7', type: 'ylqxwlcl2', img: 'icon_11.png'},
			],

			title: '',

			info:null,

			active: 'life',
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeDataTab(e) {
			this.$router.push({path: '/hydata/' + e});
		},
		changeTab(v) {
			this.nowYear = v.value;
			this.getAll(this.nowYear);
		},
		getAll(year) {
			for (let i in this) {
				if (i.indexOf('getTotal') === 0) {
					this[i](year);
				}
			}
		},

		findYear(year, list) {
			if (list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].year === year) {
						return list[i];
					}
				}
				return false;
			}
			return false;
		},

		getYears() {
			let _this = this;

			this.$http.post({
				url: '/Hydata/getQixieYears',
				data: {type: this.active},
				success(res) {
					for (let i = 0; i < res.length; i++) {
						_this.yearList.push({label: res[i] + '年', value: res[i]});
					}

					_this.endYear = res[res.length - 1];

					_this.nowYear = _this.$route.query.year ? _this.$route.query.year : res[0];

					_this.getTotal1(_this.nowYear);
				}
			});
		},

		getTotal1(year) {
			let _this = this;
			this.$http.post({
				url: '/Hydata/getQixieData',
				data: {year: year, yearlist: [year, year - 4]},
				success(res) {
					_this.info = res;

					let _pie1 = [];
					let _pie2 = [];
					let _pie3 = [];
					let _pie4 = [];
					let _pie5 = [];

					let _bar1 = {bar: [], xaxis: []};
					let _bar2 = {bar: [], xaxis: []};
					let _bar3 = {bar: [], xaxis: []};
					let _bar4 = {bar: [], xaxis: []};
					let _bar5 = {bar: [], xaxis: []};
					let _bar6 = {bar: [], xaxis: []};

					let _lifeNow = res.life.now;
					let _spdNow = res.spd.now;
					let _spdList = res.spd.list;
					let _dsfwlNow = res.dsfwl.now;
					let _dsfwlList = res.dsfwl.list;

					if (_lifeNow){
						_pie1.push({name: '非耗材类医疗器械', value: _lifeNow.income_rate});
						_pie1.push({name: '耗材类医疗器械', value: _lifeNow.profit_rate});

						for(let k in _lifeNow){
							for (let i=0;i < _this.$regexp.Province.length;i++){
								if(_this.$regexp.Province[i].value === k){
									_bar1.bar.push(parseFloat(_lifeNow[k]));
									_bar1.xaxis.push(_this.$regexp.Province[i].name);
								}
							}
						}
					}

					setTimeout(()=>{
						_this.$nextTick(()=>{

							//全生命周期
							if(_lifeNow) {
								if (_this.$refs.pie1) {
									_this.$refs.pie1.initData(_pie1, '亿元');
								}

								if (_this.$refs.bar1) {
									_this.$refs.bar1.initData(
										[
											{name: '企业数量', type: 'bar', color: 'blue', data: _bar1.bar, dw: '家'},
										],
										_bar1.xaxis
									);
								}
							}
						})
					},300)
				}
			});
		},
	}
}
</script>
