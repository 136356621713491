<template>
	<lee-page bg-img="/images/bg_head_3.jpg" :height="250" title-class="margin-top-90" title="重点企业" sub-title="IMPORTANT COMPANY" :bread="[{title:'重点企业',url:'/prize'},{title:info.title}]" v-if="info">
		<div class="wrap1100 mg-b-50">
			<div class="padding-30" v-if="info" style="background: #F5F7FB;">
				<div class="green-line">
					<div class="cl f34">
						<img src="/images/icon/icon_30.png" height="22" class="no-v-mid"/>
						{{ info.title }}
					</div>
				</div>
				<template v-if="info.check.type === 'ok'">
					<div class="base-content">
						<div class="f16 dark-1" v-html="info.html_content"></div>
						<div class="cl dark-2 f20">
							<img src="/images/icon/icon_62.png" height="20"/>
							项目简介
						</div>
						<div v-html="info.prize_info" class="dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							申报要求
						</div>
						<div v-html="info.prize_need" class="dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							项目设置
						</div>
						<div v-html="info.prize_setting" class="dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							申报结果
						</div>
						<div class="margin-top-20" style="margin-left: -10px;margin-right: -10px;">
	              <span class="text-center margin-bottom-20 padding-left padding-right inline-block" style="width:20%;padding-left:10px;padding-right:10px;" v-for="(item,index) in yearList" @click="onChangeDataTab(item)">
	                <div :class="year === item ? 'bg-blue light' : 'dark-1'" class="padding-top-bottom box-shadow-blue-small border-radius-10 f16 hand">
	                    {{ item }}年
	                </div>
	              </span>
						</div>
						<div class="margin-top" v-if="companyList.length > 0">
							<table class="lee-table margin-auto" style="width:100%;">
								<thead>
								<td width="150">序号</td>
								<td>企业名称</td>
								<td width="150">省份</td>
								</thead>
								<tr v-for="(item,index) in companyList">
									<td>{{ item.arank }}</td>
									<td>{{ item.name }}</td>
									<td>{{ item.company.province_name }}</td>
								</tr>
							</table>
						</div>
					</div>
					<lee-good-view akey="prize" :id="info.id" class="margin-top-30" :open-like="info.open_like === 1" :open-favorite="info.open_favorite === 1" :open-comment="info.open_comment === 1"></lee-good-view>
				</template>
				<vip-check-button :datas="info" :check="info.check" :price="info.price" :title="info.title" pay-title="权威榜单详情" pay-sub-title="权威榜单详情金额" type="prize" @paydone="getDetail" v-else></vip-check-button>
				<template v-if="info.check.type === 'ok'">
					<div class="margin-top-30" v-if="info.open_comment">
						<lee-comment-view akey="industry" :id="info.id"></lee-comment-view>
					</div>
				</template>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			title: '',
			id: '',
			info: null,
			year: '',
			yearList: [],
			companyList: []
		}
	},
	created() {
		this.id = this.$route.params.id;
	},
	mounted() {
		this.getDetail();
		this.getYears();
	},
	methods: {
		onChangeDataTab(e) {
			console.log(e);
			this.year = e;

			this.getCompany();
		},
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/Prize/detail',
				data: {id: this.id},
				success(res) {
					_this.info = res;
					_this.title = res.title;
				}
			});
		},
		getYears() {
			let _this = this;
			this.$http.post({
				url: '/PrizeCompany/getYears',
				data: {id: this.id},
				success(res) {
					console.log(res);
					_this.yearList = res;
					if (res.length > 0) {
						_this.year = res[0];

						_this.getCompany();
					}
				}
			});
		},
		getCompany() {
			let _this = this;
			this.$http.post({
				url: '/PrizeCompany/listAll',
				data: {tid: this.id, year: this.year, orderBy: 'arank', sortBy: 'asc'},
				success(res) {
					console.log(res);
					_this.companyList = res;
				}
			});
		},
	}
}
</script>
