<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="会议活动" sub-title="CONTENT COLUMN" height="250" title-class="margin-top-90" :bread="[{title:'会议活动',url:'/zhuanti'},{title:info.title}]" v-if="info">
		<div class="wrap1100 mg-b-100">
			<div class="bg-gray-5 pd-20">
				<div class="pd-b-10 f-24 bd-b-w-1 bd-dark-8 bd-style-solid mg-b-30">
					<img src="/images/icon/icon-36.png" height="22" class="no-v-mid"/>
					{{info.title}}
				</div>
				<lee-swiper class="padding-top-bottom mg-t-b-20" height="275" v-if="info.files && info.files.length > 0">
					<el-carousel-item v-for="(item,index) in info.files" :key="item.id" class="bg-light padding-20 margin-top-bottom" style="height: 255px;">
						<div class="hide">
							<img :src="$utils.formatUrl(item.url)" style="width: 100%;height: 215px;"/>
						</div>
					</el-carousel-item>
				</lee-swiper>
				<div class="bd-l-w-4 bd-style-solid pd-l-10" style="border-color: #09284E;">
					会议介绍
				</div>
				<div class="mg-t-20 f-16">{{info.title}}</div>
				<div class="f-14 dark-4 mg-t-10" v-html="info.content" style="white-space: pre-line;"></div>
				<div class="mg-t-20">
					<el-button type="primary" icon="mg-r-5" :class="item.icon" @click="toContent(item)" v-for="(item,index) in btnList">{{item.btn_title}}</el-button>
<!--					<el-button type="primary" icon="iconfont icon-business-icon-feeds-fill mg-r-5" @click="$router.push({path:'/zhuanti/video',query:{id:info.id}})">课件视频</el-button>-->
<!--					<el-button type="primary" icon="iconfont icon-image-text-fill mg-r-5" @click="$router.push({path:'/zhuanti/article',query:{id:info.id}})">会后总结</el-button>-->
				</div>
			</div>
		</div>
		<el-dialog title="视频播放" :visible.sync="showVideo" width="840px" @close="onCloseVideo">
			<Video :src="video" :second="3" autoplay v-if="video"/>
		</el-dialog>
	</lee-page>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			id:'',
			info:null,
			btnList:[],
			showVideo:false,
			video:null,
		}
	},
	created() {
		this.id = this.$route.params.id ? this.$route.params.id : '';
	},
	mounted() {
		if(this.id){
			this.getDetail();
		}else{
			this.$alert('内容不存在或被删除', '提示', {
				confirmButtonText: '确定',
				closeOnClickModal:false,
				closeOnPressEscape:false,
				showClose:false,
				callback: action => {
					this.$router.back();
				}
			});
		}
	},
	methods: {
		onCloseVideo(){
			this.video = null;
		},
		getDetail() {
			let _this = this;

			this.$http.post({
				url: '/Zhuanti/detail',
				data: {id:this.id},
				success(res) {
					_this.info = res;
					_this.getContent()
				}
			});
		},
		getContent() {
			let _this = this;

			this.$http.post({
				url: '/ZhuantiContent/listAll',
				data: {zhuanti_id:this.id,orderBy:'sort',sortBy:'asc'},
				success(res) {
					_this.btnList = res;
				}
			});
		},
		toContent(item){
			console.log(item);
			if(item.type === 'ppt'){
				let _url = '';
				_url = item.content.indexOf('http') >= 0 ? item.content : this.$config.assetsUrl + item.content;
				window.open('https://view.officeapps.live.com/op/view.aspx?src='+_url);

				// window.open("https://view.xdocin.com/view?src=" + encodeURIComponent(_url) + '&printable=false&copyable=false');
			}else if(item.type === 'pdf'){
				let _url = '';
				_url = item.content.indexOf('http') >= 0 ? item.content : this.$config.assetsUrl + item.content;
				window.open(_url);
				// window.open("https://view.xdocin.com/view?src=" + encodeURIComponent(_url) +'&printable=false&copyable=false');
			} else if(item.type === 'video'){
				let _url = '';
				_url = item.content.indexOf('http') >= 0 ? item.content : this.$config.assetsUrl + item.content;
				window.open(_url);
				// this.video = _url;
				// this.showVideo = true;
			} else if(item.type === 'article'){
				this.$router.push({path:'/zhuanti/article',query:{id:item.id}});
			}
		}
	}
}
</script>
