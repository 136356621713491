<template>
	<lee-page bg-img="/images/bg_head_5.jpg" title="细分领域报告及资料" sub-title="SUBDIVISION REPORTS AND DATA" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'细分领域报告及资料'}]" :height="250" title-class="margin-top-90" show-year :search="false" search-time-key="push_time">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<template v-if="list.length > 0">
				<div class="bg-gray-5 pd-20 cl mg-b-20 hand" v-for="(item,index) in list" @click="$router.push({path:'/domain/detail/'+item.id})">
					<div class="z bd-r-w-1 bd-dark-8 bd-style-solid hide" style="width: 100px;height: 60px;">
						<div class="dark-7 f-28 lh-40">{{$moment(item.create_time).format('DD')}}</div>
						<div class="dark-7 f-14 lh-20">{{$moment(item.create_time).format('YYYY.M')}}</div>
					</div>
					<div class="z pd-l-r-30" style="width: 900px">
						<div class="f-18" v-html="item.title">{{item.title}}</div>
						<div class="mg-t-10" v-if="item.tags && item.tags.length > 0"><el-tag type="info" class="bd-radius-0 h-24 lh-24 hand c-b mg-r-10" v-for="(st,si) in item.tags">{{st}}</el-tag></div>
					</div>
					<div class="y lh-50 f-30 dakr-2">&gt;</div>
				</div>
				<div class="text-center margin-top-100">
					<el-pagination
						background
						:hide-on-single-page="true"
						layout="pager, jumper"
						:page-size="searchForm.pageSize"
						:current-page="searchForm.page"
						:total="totalCount"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</template>
			<div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
				未查询到相关内容
			</div>
		</div>
  </lee-page>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      list: [],
      totalCount: 0,
	    searchForm: {
		    pageSize: 10,
		    page: 1,
		    title: '',
		    type:'domain',
	    },
      tabList: [
        {label: '医药', value: 'medicine', icon: '/images/icon/icon_57_'},
        {label: '医疗器械', value: 'apparatus', icon: '/images/icon/icon_58_'},
      ],
    }
  },
  created() {
    this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
    this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
  },
  mounted() {
    this.getList();
  },
  methods: {
    onTabChange(i) {
      this.searchForm.page = 1;
      this.getList();
    },
    getList() {
      let _this = this;
      this.$http.post({
        url: '/Report/listPage',
        data: this.searchForm,
        success(res) {
          _this.list = res.list;
          _this.totalCount = res.totalCount;
        }
      })
    },
    pageChange(page) {
      this.searchForm.page = page;
      this.$router.replace({query:{...this.searchForm}});
    },
  }
}
</script>
