<template>
    <div class="year-tab rel hide border-radius-5" ref="layout" :class="$isMobile ? 'year-tab-mobile bg-gray-2' : 'bg-light box-shadow'">
        <div class="rel cl" style="z-index: 4;">
            <div :style="{width:(100 / list.length)+'%',maxWidth:'220px'}" class="z" v-for="(item,index) in list">
                <div class="text-center hand f16" :class="item.value === year ? 'tab-trans-on bg-c-b light f-b' : 'dark-2 f-thin blue-hover'" @click="(e)=>chooseTab(e,item,index)">{{item.label}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "YearTab",
    data(){
        return{
            left:0,
            activeIndex:0,
            year:''
        }
    },
    props:{
        list:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    created() {
        let _now = new Date();
        // this.year = this.$route.query.year ? parseInt(this.$route.query.year) : _now.getFullYear() - 1;
    },
    mounted() {
        // console.log(this.$refs.layout);
        // console.log(this.list[0]);
      // console.log(this.list);
      this.$nextTick(()=>{
        this.year = this.$route.query.year ? parseInt(this.$route.query.year) : this.list[0].value;
      })

        // this.year = this.$route.query.year ? parseInt(this.$route.query.year) : this.list[0].value;
    },
    methods:{
        chooseTab(e,item,index){
            // console.log(item);
            this.$router.replace({query:{...this.$route.query,year:item.value}});
            // console.log(item);
            // this.activeIndex = index;
            // this.left = e.currentTarget.offsetLeft;
            // this.$emit('change',{value:item.value,label:item.label,index:index});
        }
    },
    watch:{
        list(n,o){
            this.year = this.$route.query.year ? parseInt(this.$route.query.year) : n[0].value;
        }
    }
}
</script>
