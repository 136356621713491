<template>
	<lee-page bg-img="/images/bg_head_4.jpg" title="行业数据" sub-title="INDUSTRY DATA" height="250" title-class="margin-top-90" :search="false">
		<div class="wrap1100 mg-t-b-50">
			<el-row :gutter="30">
				<el-col :span="8">
					<div class="hide bd-radius-10 pd-20 rel hand" style="background-image: url('/images/bg_3.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 323px;" @click="$router.push({path:'/hydata/apparatus/life'})">
						<div class="f-18 c-b">医学装备全生命周期管理</div>
						<div class="f-14 c-b">MEDICAL DATA</div>
						<div class="f-14 dark-5 abs" style="bottom:20px;left:20px;">了解更多&gt;</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="hide bd-radius-10 pd-20 rel hand" style="background-image: url('/images/bg_4.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 323px;" @click="$router.push({path:'/hydata/apparatus/spd'})">
						<div class="f-18 c-b">医疗器械SPD</div>
						<div class="f-14 c-b">INDUSTRIAL PARK DATA</div>
						<div class="f-14 dark-5 abs" style="bottom:20px;left:20px;">了解更多&gt;</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="hide bd-radius-10 pd-20 rel hand" style="background-image: url('/images/bg_4.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 323px;" @click="$router.push({path:'/hydata/apparatus/dsfwl'})">
						<div class="f-18 c-b">医疗器械第三方物流</div>
						<div class="f-14 c-b">INDUSTRIAL PARK DATA</div>
						<div class="f-14 dark-5 abs" style="bottom:20px;left:20px;">了解更多&gt;</div>
					</div>
				</el-col>
			</el-row>
		</div>
<!--		<div class="bg-img-11 text-center padding-top-bottom-100">-->
<!--			<div class="wrap1100 text-center rel">-->
<!--				<div>-->
<!--					<span class="bg-gray-4 rel border-radius-h inline-block vm" style="height: 540px;width: 540px;z-index: 2;" :style="index === 1 ? 'margin-left:-230px;margin-right: -230px' : ''" v-for="(item,index) in hyList"></span>-->
<!--				</div>-->
<!--				<div class="rel" style="margin-top:-540px">-->
<!--          <span class="inline-block padding-20 vm hide hand block-list-item rel border-radius-h" style="height: 540px;width: 540px;z-index: 3;" v-for="(item,index) in hyList" :style="index === 1 ? 'margin-left:-230px;margin-right: -230px' : ''" @click="$router.push({path:item.path})">-->
<!--            <span class="inline-block rel hide border-radius-h box-shadow" style="width: 500px;height: 500px;">-->
<!--              <img :src="item.img" height="500"/>-->
<!--              <div class="f16 block-list-off padding-left-right abs bg-light" style="line-height: 60px;bottom:0;left:0;right:0;">{{-->
<!--	                item.title-->
<!--                }}<i class="el-icon-right"></i></div>-->
<!--            </span>-->
<!--            <span class="abs block-list-on bg-blue-op padding" style="height: 100%;width: 100%;left:0;top:0;">-->
<!--              <div class="text-center" style="height: 100%;width: 100%">-->
<!--                <img src="/images/icon/icon_61.png" width="80" class="margin-top-100"/>-->
<!--                <div class="f-lq f30 light margin-top-20">{{ item.title }}</div>-->
<!--                <span class="inline-block border-w-1 border-light border-radius-h margin-top-20" style="width: 40px;height: 40px;"><i class="margin-top f20 light el-icon-right"></i></span>-->
<!--              </div>-->
<!--            </span>-->
<!--          </span>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			hyList: [
				{title: '医药数据', img: '/images/bg_cell_19.png', path: '/hydata/medical'},
				{title: '医疗器械数据', img: '/images/bg_cell_20.png', path: '/hydata/apparatus'},
				{title: '产业园区数据', img: '/images/bg_cell_21.png', path: '/hydata/park'},
			]
		}
	},
	methods: {
		prevSwiper() {
			this.$refs['swiper'].prev();
		},
		nextSwiper() {
			this.$refs['swiper'].next();
		}
	}
}
</script>
