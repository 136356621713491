<template>
	<lee-page bg-img="/images/bg_head_4.jpg" title="行业数据" sub-title="MEDICAL DATA" :height="250" title-class="margin-top-90" :bread="[{title:'行业数据',url:'/hydata/dsfwl'},{title:'医疗器械第三方物流'}]">
		<div class="bg-img-11 padding-top-bottom-50 padding-top-20" :style="{backgroundColor:'#f8f9fa'}">
			<div class="wrap1100">
				<div class="text-center" style="margin-left: -10px;margin-right: -10px;">
					<span class="margin-bottom-20 padding-left padding-right inline-block" style="width:20%;padding-left:10px;padding-right:10px;" v-for="(item,index) in tabList" @click="onChangeDataTab(item.type)">
							<div :class="active === item.type ? 'bg-blue light' : 'dark-1'" class="padding-top-bottom box-shadow-blue-small border-radius-10 f14 hand" style="height: 44px;overflow: hidden;">
								<span style="line-height: 24px;">{{item.title}}</span>
							</div>
					</span>
				</div>
			</div>
			<check-vip akey="hydata-qixie-dsfwl">
				<div class="wrap1100 padding-bottom-100" v-if="info">
					<lee-year-tab :list="yearList"></lee-year-tab>
					<div class="box-shadow padding-30 bg-light margin-top-20">
						<div class="cl f34">
							<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#00A3DC" class="z margin-right"></lee-img-icon>
							<div class="z f22">行业数据 - 医疗器械第三方物流
							</div>
						</div>
						<div class="f16 dark-2">
							根据国家药监局发布的数据显示，{{nowYear}}年我国医疗器械第三方物流企业数量为{{info.dsfwl.now.total_income}}家，我国医疗器械第三方物流企业数量逐年下降，说明随着医疗器械第三方物流行业集中度不断提升。
						</div>
						<div>
							<lee-bar-echarts
								ref="bar5"
								:grid-bottom="30"
								height="350px"
								:show-legend="true"
								:show-left="true"
								:show-right="false"></lee-bar-echarts>
						</div>
						<div class="f16 dark-2 pd-t-50">
							{{nowYear}}年各地区医疗器械物流企业分布。
						</div>
						<div>
							<lee-bar-echarts
								ref="bar6"
								:grid-bottom="30"
								height="350px"
								:show-legend="true"
								:show-left="true"
								:show-right="false"></lee-bar-echarts>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "life",
	data() {
		return {
			yearList: [],
			nowYear: '',

			startYear: '',
			endYear: '',

			yearRange: [],

			tabList: [
				{title: '医疗设备全周期服务管理', link: '#a1', type: 'life', img: 'icon_9.png'},
				{title: '医疗器械SPD', link: '#a2', type: 'spd', img: 'icon_10.png'},
				{title: '医疗器械第三方物流', link: '#a3', type: 'dsfwl', img: 'icon_11.png'},
				{title: '市场规模', link: '#a4', type: 'ylqxsc', img: 'icon_11.png'},
				{title: '物流总费用', link: '#a5', type: 'ylqxsc2', img: 'icon_11.png'},
				{title: '物流仓储', link: '#a6', type: 'ylqxwlcl', img: 'icon_11.png'},
				{title: '物流运输车辆', link: '#a7', type: 'ylqxwlcl2', img: 'icon_11.png'},
			],

			title: '',

			info:null,

			active: 'dsfwl',
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeDataTab(e) {
			this.$router.push({path: '/hydata/' + e});
		},
		changeTab(v) {
			this.nowYear = v.value;
			this.getAll(this.nowYear);
		},
		getAll(year) {
			for (let i in this) {
				if (i.indexOf('getTotal') === 0) {
					this[i](year);
				}
			}
		},

		findYear(year, list) {
			if (list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].year === year) {
						return list[i];
					}
				}
				return false;
			}
			return false;
		},

		getYears() {
			let _this = this;

			this.$http.post({
				url: '/Hydata/getQixieYears',
				data: {type: this.active},
				success(res) {
					for (let i = 0; i < res.length; i++) {
						_this.yearList.push({label: res[i] + '年', value: res[i]});
					}

					_this.endYear = res[res.length - 1];

					_this.nowYear = _this.$route.query.year ? _this.$route.query.year : res[0];

					_this.getTotal1(_this.nowYear);
				}
			});
		},

		getTotal1(year) {
			let _this = this;
			this.$http.post({
				url: '/Hydata/getQixieData',
				data: {year: year, yearlist: [year, year - 4]},
				success(res) {
					_this.info = res;

					let _pie1 = [];
					let _pie2 = [];
					let _pie3 = [];
					let _pie4 = [];
					let _pie5 = [];

					let _bar1 = {bar: [], xaxis: []};
					let _bar2 = {bar: [], xaxis: []};
					let _bar3 = {bar: [], xaxis: []};
					let _bar4 = {bar: [], xaxis: []};
					let _bar5 = {bar: [], xaxis: []};
					let _bar6 = {bar: [], xaxis: []};

					let _lifeNow = res.life.now;
					let _spdNow = res.spd.now;
					let _spdList = res.spd.list;
					let _dsfwlNow = res.dsfwl.now;
					let _dsfwlList = res.dsfwl.list;

					if (_lifeNow){
						_pie1.push({name: '非耗材类医疗器械', value: _lifeNow.income_rate});
						_pie1.push({name: '耗材类医疗器械', value: _lifeNow.profit_rate});

						for(let k in _lifeNow){
							for (let i=0;i < _this.$regexp.Province.length;i++){
								if(_this.$regexp.Province[i].value === k){
									_bar1.bar.push(parseFloat(_lifeNow[k]));
									_bar1.xaxis.push(_this.$regexp.Province[i].name);
								}
							}
						}
					}

					if(_spdList && _spdList.length > 0){
						for(let i=0;i < _spdList.length;i++){
							_pie4.push({name: _spdList[i].year+'年', value: parseFloat(_spdList[i].total_profit)});
						}
					}

					if(_spdNow) {
						_pie2.push({name: '三级医院占比', value: _spdNow.values[0].val.income});
						_pie2.push({name: '三甲医院占比', value: _spdNow.values[1].val.income});

						_pie3.push({name: '综合医院占比', value: _spdNow.values[2].val.income});
						_pie3.push({name: '专科医院占比', value: _spdNow.values[3].val.income});

						_bar2.bar.push(parseFloat(_spdNow.values[4].val.income));
						_bar2.bar.push(parseFloat(_spdNow.values[5].val.income));
						_bar2.bar.push(parseFloat(_spdNow.values[6].val.income));
						_bar2.bar.push(parseFloat(_spdNow.values[7].val.income));
						_bar2.bar.push(parseFloat(_spdNow.values[8].val.income));
						_bar2.xaxis = ['1000万元及以下','1000万元-1亿元','1亿元-10亿元','10亿元-20亿元','20亿元及以上'];

						for(let k in _spdNow){
							for (let i=0;i < _this.$regexp.Province.length;i++){
								if(_this.$regexp.Province[i].value === k){
									_bar3.bar.push(parseFloat(_spdNow[k]));
									_bar3.xaxis.push(_this.$regexp.Province[i].name);
								}
							}
						}

						_pie5.push({name: '医疗器械流通企业', value: _spdNow.values[9].val.income});
						_pie5.push({name: '非医疗器械流通企业', value: _spdNow.values[10].val.income});

						_bar4.bar.push(parseFloat(_spdNow.values[11].val.income));
						_bar4.bar.push(parseFloat(_spdNow.values[12].val.income));
						_bar4.bar.push(parseFloat(_spdNow.values[13].val.income));
						_bar4.bar.push(parseFloat(_spdNow.values[14].val.income));
						_bar4.bar.push(parseFloat(_spdNow.values[15].val.income));
						_bar4.xaxis = ['服务费率≦1%','1%<服务费率≦2%','2%<服务费率≦3%','3%<服务费率≦4%','4%<服务费率'];
					}

					if(_dsfwlList && _dsfwlList.length > 0){
						for(let i=0;i < _dsfwlList.length;i++){
							_bar5.bar.push(parseFloat(_dsfwlList[i].total_income));
							_bar5.xaxis.push(_dsfwlList[i].year+'年');
						}
					}

					if(_dsfwlNow){
						for(let k in _dsfwlNow){
							for (let i=0;i < _this.$regexp.Province.length;i++){
								if(_this.$regexp.Province[i].value === k){
									_bar6.bar.push(parseFloat(_dsfwlNow[k]));
									_bar6.xaxis.push(_this.$regexp.Province[i].name);
								}
							}
						}
					}

					setTimeout(()=>{
						_this.$nextTick(()=>{

							//全生命周期
							if(_lifeNow) {
								if (_this.$refs.pie1) {
									_this.$refs.pie1.initData(_pie1, '亿元');
								}

								if (_this.$refs.bar1) {
									_this.$refs.bar1.initData(
										[
											{name: '企业数量', type: 'bar', color: 'blue', data: _bar1.bar, dw: '家'},
										],
										_bar1.xaxis
									);
								}
							}

							//spd
							if(_spdNow || (_spdList && _spdList.length > 0)) {
								if (_this.$refs.bar2) {
									_this.$refs.bar2.initData(
										[
											{name: '中标数量', type: 'bar', color: 'blue', data: _bar2.bar, dw: '个'},
										],
										_bar2.xaxis
									);
								}

								if (_this.$refs.bar3) {
									_this.$refs.bar3.initData(
										[
											{name: '企业数量', type: 'bar', color: 'blue', data: _bar3.bar, dw: '家'},
										],
										_bar3.xaxis
									);
								}

								if (_this.$refs.bar4) {
									_this.$refs.bar4.initData(
										[
											{name: '企业数量', type: 'bar', color: 'blue', data: _bar4.bar, dw: '家'},
										],
										_bar4.xaxis
									);
								}

								if (_this.$refs.pie2) {
									_this.$refs.pie2.initData(_pie2, '家');
								}

								if (_this.$refs.pie3) {
									_this.$refs.pie3.initData(_pie3, '家');
								}

								if (_this.$refs.pie4) {
									_this.$refs.pie4.initData(_pie4, '家');
								}

								if (_this.$refs.pie5) {
									_this.$refs.pie5.initData(_pie5, '家');
								}
							}

							if(_dsfwlList && _dsfwlList.length > 0){
								if (_this.$refs.bar5) {
									_this.$refs.bar5.initData(
										[
											{name: '企业数量', type: 'bar', color: 'blue', data: _bar5.bar, dw: '家'},
										],
										_bar5.xaxis
									);
								}

								if (_this.$refs.bar6) {
									_this.$refs.bar6.initData(
										[
											{name: '企业数量', type: 'bar', color: 'blue', data: _bar6.bar, dw: '家'},
										],
										_bar6.xaxis
									);
								}
							}

						})
					},300)
				}
			});
		},
	}
}
</script>
