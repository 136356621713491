<template>
	<lee-page bg-img="/images/bg_head_9.jpg" :title="title" :sub-title="subTitle" :height="250" search title-class="margin-top-70" show-year search-time-key="push_time" :show-search-type="false" :bread="[{title:'会议活动',url:'/zhuanti'},{title:typeInfo.name}]" v-if="typeInfo">
		<div class="padding-bottom-100">
			<div class="wrap1100">
				<template v-if="list.length > 0">
					<el-row :gutter="20" class="mg-t-30">
						<el-col :span="8" v-for="(item,index) in list">
							<div class="bg-light mg-b-20 hand" @click="$router.push({path:'/zhuanti/detail/'+item.id})" style="height: 395px;">
								<img :src="$utils.formatUrl(item.head_img)" style="width: 100%;height: 200px;"/>
								<div class="pd-10">
									<div class="f-18 text-line-2" style="height: 50px;">{{item.title}}</div>
									<div class="f-14 dark-4 text-line-2 mg-t-5" style="height: 40px;line-height: 20px;">{{item.des}}</div>
									<div class="f-12 dark-4 mg-t-10">
										<i class="el-icon-location-information mg-r-10 f-14 vm"></i>{{item.address}}
									</div>
									<div class="f-12 dark-4 mg-t-5">
										<i class="el-icon-time mg-r-10 f-14 vm"></i>{{$moment(item.start_time).format('YYYY年MM月DD日')}}-{{$moment(item.end_time).format('YYYY年MM月DD日')}}
									</div>
									<div class="mg-t-15 c-b-2 f-12">查看更多&gt;</div>
								</div>
							</div>
						</el-col>
					</el-row>
					<div class="text-center margin-top-100">
						<el-pagination
							background
							:page-size="pageSize"
							:current-page.sync="searchForm.page"
							:total="totalCount"
							layout="prev,pager, next"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</template>
				<template v-else>
					<div class="text-center bg-light padding-top-bottom-40 f-thin f20 dark-3">
						未查询到相关内容
					</div>
				</template>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			title: '',
			id: '',
			subTitle:'',
			list: [],
			pageSize: 10,
			totalCount: 0,
			tabs: [],
			searchForm: {
				page: 1,
				title: ''
			},
			typeInfo:null
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.$route.query};
		}

		this.id = this.$route.params.id;
		this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
		this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
	},
	mounted() {
		this.getList();
		this.getType();
	},
	methods: {
		getList() {
			let _this = this;

			if (this.id) {
				this.$http.post({
					url: '/Zhuanti/listPage',
					data: {...this.searchForm, pageSize: this.pageSize, type_zhuanti_id: this.id},
					success(res) {
						_this.list = res.list;
						_this.totalCount = res.totalCount;
					}
				});
			}
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.$router.replace({query: {...this.searchForm}});
		},
		getType() {
			let _this = this;

			if (this.id) {
				this.$http.post({
					url: '/Type/detail',
					data: {id: this.id},
					success(res) {
						_this.typeInfo = res;
						_this.title = res.name;
						_this.subTitle = res.content;
					}
				});
			}
		},
	}
}
</script>
