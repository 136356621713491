<template>
	<div>
		<div class="bg-light box-shadow-base pd-10" v-if="data">
			<div class="lh-40 f-14 bd-b-w-1 bd-dark-7 bd-style-solid">数据平台行业数据分析报告—医疗器械SPD</div>
			<div class="mg-t-10 f-14 dark-3">据分会统计信息显示，三级医院占比{{ (data.now.values[0].val.income_percent * 100).toFixed(2) }}%，其中三甲医院所占比例高达{{ (data.now.values[1].val.income_percent * 100).toFixed(2) }}%。</div>
		</div>
		<div class="mg-t-30 bg-light box-shadow-base pd-30">
			<lee-pie-echarts
				ref="pie2"
				:grid-bottom="30"
				height="200px"
				:show-legend="false"
				label-format="{b}:{d}%"
				label-pos="outside"
				:radius="['0%','80%']"></lee-pie-echarts>
		</div>
	</div>
</template>

<script>
export default {
	name: "IndexHyData7",
	data() {
		return {
			data: null,
			nowYear: '',
			endYear: ''
		}
	},
	props: {
		// year:{
		//     type:[String,Number],
		//     default:''
		// },
		type: {
			type: String,
			default: ''
		}
	},
	created() {

	},
	mounted() {
		this.getYears();
	},
	methods: {
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/Hydata/getQixieYears',
				data: null,
				success(res) {
					_this.endYear = res[res.length - 1];

					_this.nowYear = res[0];

					_this.getTotal1(_this.nowYear, _this.endYear);
				}
			});
		},
		getTotal1(startYear, endYear) {
			let _this = this;

			if (this.type) {
				this.$http.post({
					url: '/Hydata/getTypeData',
					data: {year: startYear, type: this.type, yearlist: [startYear, endYear]},
					success(res) {
						_this.data = res;

						let _pie2 = [];

						let _spdNow = res.now;

						if (_spdNow) {
							_pie2.push({name: '三级医院占比', value: _spdNow.values[0].val.income});
							_pie2.push({name: '三甲医院占比', value: _spdNow.values[1].val.income});
						}

						setTimeout(() => {
							_this.$nextTick(() => {

								//全生命周期
								if (_spdNow) {
									if (_this.$refs.pie2) {
										_this.$refs.pie2.initData(_pie2, '家');
									}
								}
							})
						}, 300);
					}
				})
			}
		},
	}
}
</script>
