<template>
    <div id="page">
        <div class="page" :style="{backgroundImage:'url('+bgImg+')',height:height ? height +'px' : '526px'}">
            <div id="nav" :style="{backgroundColor:'rgba(255,255,255,'+op.op+')'}" :class="op.class">
                <div style="width: 1300px;margin:0 auto;">
                    <ul class="cl head">
                        <li class="logo"><img :src="'/images/'+op.logo" height="40" class="margin-top-5"/></li>
                        <li v-for="(item,index) in $config.topMenu"><a href="javascript:void(0);" :class="item.path ==='/' ? ($route.path === item.path ? 'on' : '') : $route.path.indexOf(item.path) === 0 ? 'on' : ''" @click="$router.push({path:item.path})">{{item.title}}</a></li>
                        <li class="y" style="line-height:62px;width: auto !important;" v-if="!isLogin">
                            <el-button type="primary" size="mini" class="vm" @click="$router.push({path:'/login'})">登录</el-button>
                        </li>
                        <li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">
                            <el-button type="primary" size="mini" class="vm" @click="logout">退出</el-button>
                        </li>
                        <li class="y f18 light" style="line-height:62px;width: auto !important;" v-if="isLogin">
                            <a href="javascript:void(0);" @click="$router.push({path:'/user/profile'})">{{$user.getNickName() ? $user.getNickName() : '立即完善资料'}}</a>
                        </li>
                        <li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">
<!--                            <a @click="$router.push({path:'/user/vip'})">续费VIP</a>-->
                            <a @click="$router.push({path:'/user'})">续费VIP</a>
                        </li>
                        <li class="y" style="line-height:62px;width: auto !important;" v-if="!isLogin">
                            <el-button type="primary" size="mini" plain class="light vm" @click="$router.push({path:'/register'})">注册会员</el-button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="cl"></div>
            <div class="light wrap1100 text-line-2" :class="search ? (this.titleClass ? this.titleClass + ' f48' : 'margin-top-200 f48') : (this.titleClass ? this.titleClass + ' f48' : 'margin-top-200 f48')" v-if="title">{{title}}</div>
            <div class="light wrap1100 f-thin margin-top f22">{{subTitle}}</div>
            <div class="text-center f20 dark-2 margin-top-20">{{des}}</div>
<!--            <div class="margin-auto" style="width: 1100px" v-if="!$slots.search && search && !$isMobile">-->
<!--                <lee-search :searchPath="searchPath" :searchSlugs="searchSlugs" :stopSearch="stopSearch" :toPath="toPath" :showYear="showYear" @search="onSearch" :search-time-key="this.searchTimeKey"></lee-search>-->
<!--            </div>-->
            <slot name="search"></slot>
<!--            <slot name="header"></slot>-->
        </div>
        <div class="padding-left-right" v-if="!$slots.search && search" style="margin-top: -50px;">
            <lee-search :showSearchType="showSearchType"  :searchPath="searchPath" :searchSlugs="searchSlugs" :stopSearch="stopSearch" :toPath="toPath" class="box-shadow" @search="onSearch"></lee-search>
        </div>
        <div style="height: 40px;line-height:40px;" class="mg-t-b-30 wrap1100 rel cl" v-if="bread.length > 0">
            <div class="z f16">
                <i class="el-icon-location-outline f20"></i>
                当前位置：
            </div>
            <el-breadcrumb separator-class="el-icon-arrow-right" style="line-height:40px;">
                <el-breadcrumb-item :to="{ path: '/' }" class="f16 dark-3">首页</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item,index) in bread" class="f16" :class="index + 1 === bread.length ? 'c-b text-line-1 hide' : 'dark-3'" style="max-width: 430px;" :to="item.url ? { path: item.url } : null">{{item.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div :style="{marginTop:!$isMobile && bread.length > 0 ? '-20px' : ''}">
            <slot></slot>
        </div>
        <div class="bg-footer box-shadow mg-t-40">
            <div class="wrap1100 padding-top-50 padding-bottom-20">
                <el-row>
                    <el-col :span="8">
                        <div class="light" style="height: 22px;line-height:22px;overflow: hidden;">联系我们</div>
<!--                        <div class="green-line margin-top margin-bottom"></div>-->
                        <div class="f12 light mg-t-20" v-html="contact_us">{{contact_us}}
<!--                          办公地点：北京市丰台区丽泽路16号院2号楼铭丰大厦11层<br/>-->
<!--                          邮编：100073<br/>-->
<!--                          联系电话：010-83775866-->
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="light" style="height: 22px;line-height:22px;overflow: hidden;"></div>
<!--                        <div class="green-line margin-top margin-bottom"></div>-->
                        <div class="f12 light mg-t-20" v-html="help_center">{{help_center}}
<!--                          医药分会：<br/>联系客服 崔新乐 13810992439 / 商务合作 常洋 18610639524-->
                        </div>
<!--                        <div class="f12 light mg-t-5">-->
<!--                          医疗器械供应链分会：<br/>联系客服 刘亚楠 13811246436 / 商务合作 田芬 13161620006-->
<!--                        </div>-->
                    </el-col>
                    <el-col :span="8">
<!--                        <img src="/images/icon/icon_qq_off.png" height="42"/>-->
<!--                        <img src="/images/icon/icon_weixin_off.png" height="42"/>-->
<!--                        <img src="/images/icon/icon_weibo_off.png" height="42"/>-->
                      <el-popover
                          placement="top"
                          width="230"
                          trigger="hover" v-if="weixin_gongzhong_qrcode">
                        <img :src="weixin_gongzhong_qrcode" height="200" class="vm"/>
                        <img :src="weixin_gongzhong_qrcode" slot="reference" height="88" class="margin-left-20"/>
                      </el-popover>
											<el-popover
												placement="top"
												width="230"
												trigger="hover" v-if="weixin_shipin_qrcode">
												<img :src="weixin_shipin_qrcode" height="200" class="vm"/>
												<img :src="weixin_shipin_qrcode" slot="reference" height="88" class="margin-left-20"/>
											</el-popover>
<!--                      <el-popover-->
<!--                          placement="top"-->
<!--                          width="230"-->
<!--                          trigger="hover">-->
<!--                        <img src="/images/qrcode2.png" height="200" class="vm"/>-->
<!--                        <img src="/images/qrcode2.png" slot="reference" height="88" class="margin-left-20"/>-->
<!--                      </el-popover>-->
                    </el-col>
                </el-row>
                <div class="text-center f12 light margin-top-50">
                  copyright {{copyright}} ©2018-{{$moment().format('YYYY')}}<a href="https://beian.miit.gov.cn" target="_blank" class="margin-left-20">京ICP备2020048796号</a>
                </div>
            </div>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {
    name: "Page",
    props:{
        bgImg:String,
        height:{
            type:[String,Number],
            default:''
        },
        search:{
            type:Boolean,
            default:false
        },
	    showSearchType:{
            type:Boolean,
            default:true
        },
        back:{
            type:Boolean,
            default:false
        },
        title:{
            type:String,
            default:''
        },
        miniTitle:{
            type:[String,Object],
            default:''
        },
        subTitle:{
            type:String,
            default:''
        },
        des:{
            type:String,
            default:''
        },
        bread:{
            type:Array,
            default:()=>{
                return []
            }
        },
        titleClass:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:'light'
        },
        searchPath:{
            type:String,
            default:'',
        },
        searchSlugs:{
            type:Array,
            default(){
                return []
            }
        },
        stopSearch:{
            type:Boolean,
            default:false
        },
        showYear:{
            type:Boolean,
            default:false
        },
        toPath:{
            type:String,
            default:'',
        },
      searchTimeKey:{
          type:String,
        default:'create_time'
      }
    },
    data(){
        return{
            scrollTop:0,
            isLogin:false,
					contact_us:'',
					help_center:'',
					weixin_gongzhong_qrcode:'',
					weixin_shipin_qrcode:'',
					copyright:''
        }
    },
    mounted() {
        this.handleScroll();
        window.addEventListener('scroll',this.handleScroll);
        this.isLogin = !!this.$user.getToken();
				this.getSetting()
    },
    methods:{
        handleScroll(e){
            this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        },
        logout(){
            this.$user.logout();
            this.$message.success('你已退出登录');

            location.reload();
            // this.$router.push({path:'/login'});
        },
        onSearch(res){
            this.$emit('onSearch',res);
        },
			getSetting(){
				let _this = this;

				this.$http.post({
					url: '/SystemSetting/getSystem',
					data:{skey:['contact_us','help_center','weixin_gongzhong_qrcode','weixin_shipin_qrcode','copyright']},
					success(res) {
						console.log(res)
						_this.contact_us = res.contact_us ? res.contact_us.replaceAll("\n",'<br/>') : '';
						_this.help_center = res.help_center ? res.help_center.replaceAll("\n",'<br/>') : '';
						_this.weixin_gongzhong_qrcode = res.weixin_gongzhong_qrcode ? (res.weixin_gongzhong_qrcode.indexOf('http') >= 0 ? res.weixin_gongzhong_qrcode : _this.$config.assetsUrl + res.weixin_gongzhong_qrcode) : '';
						_this.weixin_shipin_qrcode = res.weixin_shipin_qrcode ? (res.weixin_shipin_qrcode.indexOf('http') >= 0 ? res.weixin_shipin_qrcode : _this.$config.assetsUrl + res.weixin_shipin_qrcode) : '';
						_this.copyright = res.copyright ? res.copyright : '';
					}
				});
			}
    },
    computed: {
        op(){
            let _op = this.type === 'light' ? this.scrollTop / 50 : 1;

            return _op >= 1 ? {op:1,class:'nav-dark',logo:'logo_dark2.png',color:'#333'} : {op:_op,class:'',logo:'logo_dark2.png',color:'#fff'};
        },
    }
}
</script>
<style scoped>
.head{list-style: none;height: 52px;overflow: hidden;}
.head li{float: left;margin-right:5px;display: inline-block;}
.head li.logo{width:40px;}
.head li a{color:#fff;font-size: 16px;display: block;padding:0 13px;line-height: 62px;height: 52px; text-align: center;}
.head li a.on{}
.nav-dark .head li a{color:#333 !important;}
.nav-dark .head li a.on{color: #333 !important;border-bottom: 3px solid #003D8C;}
</style>
