<template>
  <lee-page bg-img="/images/bg_head_2.jpg" height="250" title-class="margin-top-90" :title="data.policy.name" :sub-title="data.policy.content" :bread="[{title:'政策',url:'/policy'},{title:data.policy.name,url:'/policy/list/'+data.type_policy_id},{title:data.title}]" v-if="data">
	  <div class="wrap1100" v-if="data">
		  <div class="pd-30" style="background: #F5F7FB;">
			  <div class="green-line">
				  <div class="cl f34">
					  <img src="/images/icon/icon-999.png" height="22" class="no-v-mid margin-right"/>{{ data.title }}
				  </div>
				  <el-row :gutter="20" class="dark-3 margin-top f14">
					  <el-col :md="8" :xs="12" v-if="data.department">
						  <el-tooltip effect="dark" :content="data.department.name" placement="top">
							  <div class="text-line-1">发布机构：{{ data.department.name }}</div>
						  </el-tooltip>
					  </el-col>
					  <el-col :md="8" :xs="12">发布时间：{{ data.push_time }}</el-col>
					  <el-col :md="8" :xs="12" v-if="data.doc_number">文号：{{ data.doc_number }}</el-col>
				  </el-row>
				  <div class="margin-top" v-if="data.yuanwen_url || data.jiedu_url">
					  <a style="background-color: #3288fd;color:#fff;padding:5px 10px;border-radius: 3px;" :href="data.yuanwen_url.indexOf('http') >= 0 ? data.yuanwen_url : 'http://'+data.yuanwen_url" class="f14" v-if="data.yuanwen_url" target="_blank">阅读原文 &gt;&gt;</a>
					  <a style="margin-left:20px;background-color: #3288fd;color:#fff;padding:5px 10px;border-radius: 3px;" :href="data.jiedu_url.indexOf('http') >= 0 ? data.jiedu_url : 'http://'+data.jiedu_url" class="f14" v-if="data.jiedu_url" target="_blank">政策解读 &gt;&gt;</a>
				  </div>
			  </div>
			  <div class="bg-light pd-20" v-if="data.des">
				  <div class="dark-4 f-14">内容简介：</div>
				  <div class="f16 dark-5 base-content mg-t-10" style="white-space: pre-wrap;" v-html="data.des" v-if="data.des">{{data.des}}</div>
			  </div>
			  <vip-check-box :data="data" type="policy" akey="policy" @paydone="getDetail" pay-title="购买政策详情" pay-sub-title="政策详情金额"></vip-check-box>
<!--			  <div class="f16 dark-1 base-content" v-html="data.html_content ? data.html_content : ''" v-if="data.check.type === 'ok'"></div>-->
<!--			  <template v-if="data.check.type === 'ok'">-->
<!--				  <div class="margin-top-20" v-if="data.files.length > 0">-->
<!--					  <lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" akey="policy" :tid="data.id"></lee-vip-file-down>-->
<!--					  &lt;!&ndash;              <lee-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom"></lee-file-down>&ndash;&gt;-->
<!--				  </div>-->
<!--			  </template>-->
<!--			  <vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" pay-title="购买政策详情" pay-sub-title="政策详情金额" type="policy" @paydone="getDetail" v-else></vip-check-button>-->
			  <lee-good-view class="margin-top-50" :id="data.id" akey="policy" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1" :open-comment="data.open_comment === 1"></lee-good-view>
			  <lee-comment-view total-count="30" akey="policy" :id="data.id"></lee-comment-view>
		  </div>
	  </div>
  </lee-page>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      title: '',
      type: 'policy',
      id: '',
      data: null,
    }
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let _this = this;

      if (this.id) {
        this.$http.post({
          url: '/Policy/detail',
          data: {id: this.id},
          success(res) {
            _this.data = res;
          }
        });
      }
    },
  }
}
</script>
