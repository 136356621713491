<template>
	<div :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
export default {
	name: "BarEcharts",
	data(){
		return{
			id:'echarts',
			option:{
				title:{
					text:'',
					left:'center',
					show:false,
					textStyle:{
						fontWeight:'normal',
						fontSize:18
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend:null,
				grid: {
					left: 0,
					right: '20%',
					bottom: 0,
					top:0,
					containLabel: true
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
				},
				series: []
			}
		}
	},
	props:{
		width:String,
		height:String,
		title:String,
		showLegend:{
			type:Boolean,
			default:true
		},
		legendOrient:{
			type:String,
			default:'horizontal'
		},
		titlePos:{
			type:String,
			default:'top'
		},
		titleSize:{
			type:Number,
			default:18
		},
		showTitle:{
			type:Boolean,
			default:true
		},
		barWidth:{
			type:Number,
			default:40
		},
		showLeft:{
			type:Boolean,
			default:true
		},
		showRight:{
			type:Boolean,
			default:true
		},
		data:{
			type:Array,
			default(){
				return []
			}
		},
		legendPos:{
			type:String,
			default:'bottom'
		},
		xAxis:{
			type:Array,
			default(){
				return []
			}
		},
		gridBottom:{
			type:Number,
			default:30
		},
		gridTop:{
			type:Number,
			default:30
		}
	},
	created() {
		this.id = this.$utils.randomWords(8);



		if(this.titlePos === 'top'){
			this.option.title.top = 0;
		}else if(this.titlePos === 'bottom'){
			this.option.title.bottom = 0;
		}


		this.option.grid.bottom = this.gridBottom;
		this.option.grid.top = this.gridTop;

		this.option.title.textStyle.fontSize = this.titleSize;
		this.option.title.text = this.title;
		this.option.title.show = this.showTitle;

		// this.option.xAxis[0].axisLabel.show = this.showLeft;
		// this.option.xAxis[1].axisLabel.show = this.showRight;
		//
	},
	mounted() {
		this.echarts = this.$echarts.init(document.getElementById(this.id));
		this.echarts.setOption(this.option);
	},
	methods:{
		initData(data,xaxis){
			console.log(data);
			console.log(xaxis);

			this.option.series = [];

			if(xaxis.length > 0){
				this.option.yAxis.data = xaxis;
			}

			if(data.length > 0){
				let _legend = [];
				for (let i = 0;i < data.length;i++){
					let _data = data[i];

					_legend.push(_data.name);
					if(_data.type === 'bar'){
						this.option.series.push(this.$echartsJs.getBar(_data.data, _data.name,_data.color,this.barWidth,'right'));
						// if(_data.color === 'green') {
						//     this.option.series.push(this.$echartsJs.getGreenBar(_data.data, _data.name,this.barWidth));
						// }else if(_data.color === 'blue'){
						//     this.option.series.push(this.$echartsJs.getBlueBar(_data.data, _data.name,this.barWidth));
						// }
					}else if(_data.type === 'line'){
						if(_data.color === 'purple') {
							this.option.series.push(this.$echartsJs.getPurpleLine(_data.data,_data.name));
						}
					}
				}

				this.option.tooltip.formatter = (params)=>{
					let relVal = params[0].name;
					for (let i = 0, l = params.length; i < l; i++) {
						relVal += '<div style="display:flex;flex-direction: row;align-items: center;justify-content: space-between;"><span style="flex:1;margin-right:10px;">' + params[i].marker + (params[i].seriesName ? params[i].seriesName : params[i].name) + ' : </span><span style="flex:1;text-align: right;">' + params[i].value + '</span>'+ (data[i] && data[i].dw ? data[i].dw : '') +'</div>'
					}
					return relVal;
				}

				if(this.showLegend) {
					this.option.legend = this.$echartsJs.getLegend(_legend,this.legendPos,this.legendOrient);
				}

				this.echarts.setOption(this.option);
			}
		}
	},
	watch:{
		title(n,o){
			// console.log(n);
			// this.initData(n);
			this.option.title.text = n;
			this.echarts.setOption(this.option,true);
		}
	}
}
</script>
