<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="会议活动" sub-title="CONTENT COLUMN" height="250" title-class="margin-top-70" :bread="[{title:'会议活动',url:'/zhuanti'},{title:'会议介绍',url:'/zhuanti/detail/'+info.zhuanti_id},{title:info.btn_title}]" v-if="info">
		<div class="wrap1100" v-if="info">
			<div class="pd-30" style="background: #F5F7FB;">
				<div class="green-line">
					<div class="cl f34">
						<img src="/images/icon/icon-999.png" height="22" class="no-v-mid margin-right"/>{{ info.title }}
					</div>
				</div>
				<div class="f16 dark-1 base-content" v-html="info.content ? info.content : ''"></div>
				<template v-if="info && info.files && info.files.length > 0">
					<div class="f16 dark-3" style="border-top:1px solid #ddd;padding:10px 0;">附件列表：</div>
					<div v-for="(item,index) in info.files">
						<div style="background-color: #003D8C;color:#fff;font-size: 14px;margin-bottom: 10px;padding:5px 15px;border-radius: 999px;display: inline-block;cursor: pointer;" @click="toDownload(item)"><i class="el-icon-download"></i>{{item.name}}</div>
					</div>
				</template>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "articledetail",
	data() {
		return {
			id:'',
			info:null
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		if(this.id){
			this.getDetail();
		}else{
			this.$alert('会后总结内容不存在 或 被删除', '提示', {
				confirmButtonText: '确定',
				closeOnClickModal:false,
				closeOnPressEscape:false,
				showClose:false,
				callback: action => {
					this.$router.back();
				}
			});
		}
	},
	methods: {
		toDownload(item){
			console.log(item);
			if(item.url){
				window.open(item.url.indexOf('http') >= 0 ? item.url : this.$config.assetsUrl + item.url,'_blank');
			}
		},
		getDetail() {
			let _this = this;

			this.$http.post({
				url: '/ZhuantiContent/detail',
				data: {id:this.id},
				success(res) {
					if(res) {
						_this.info = res;
					}else{
						_this.$alert('会后总结内容不存在 或 被删除', '提示', {
							confirmButtonText: '确定',
							closeOnClickModal:false,
							closeOnPressEscape:false,
							showClose:false,
							callback: action => {
								_this.$router.back();
							}
						});
					}
				}
			});
		},
	}
}
</script>
