<template>
	<lee-page bg-img="/images/bg_head_2.jpg" title="重点企业" sub-title="IMPORTANT COMPANY" :height="250" title-class="margin-top-70" des="" :search="true" :bread="[{title:'重点企业',url:'/prize'},{title:'搜索'}]">
		<div class="padding-bottom-100 padding-top-50">
			<div class="wrap1100">
				<template v-if="list.length > 0">
					<el-row class="border-left">
						<el-col :span="8" v-for="(item,index) in list" class="border-top border-right border-bottom">
							<div class="bg-light padding-20 bg-blue-hover hand" @click="$router.push({path:'/prize/detail/'+item.id})">
								<img :src="item.head_img.indexOf('http') >= 0 ? item.head_img : $config.assetsUrl + item.head_img" width="100%" height="200"/>
								<div class="margin-top dark-1 f18 text-line-2" style="height: 50px;line-height: 25px;" v-html="item.title"></div>
								<div class="margin-top dark-3 f14 text-line-2 f-thin" style="height: 40px;line-height: 20px;">{{item.content}}</div>
								<div class="margin-top">
									<el-button type="primary" size="mini">查看更多<i class="el-icon-right el-icon--right"></i></el-button>
								</div>
							</div>
						</el-col>
					</el-row>
					<div class="text-center margin-top-100">
						<el-pagination
							background
							layout="prev,pager,next"
							:page-size="pageSize"
							:current-page.sync="searchForm.page"
							:total="total"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</template>
				<template v-else>
					<div class="text-center padding-top-bottom-40 f20 dark-3">
						未查询到相关内容
					</div>
				</template>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			title: '',
			list: [],
			pageSize: 10,
			total: 0,
			searchForm: {
				page: 1,
				title: '',
				title_type: '',
			}
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.$route.query};
		}

		this.searchForm.page = this.searchForm.page ? parseInt(this.searchForm.page) : 1;
		this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
		this.searchForm.title_type = this.$route.query.title_type ? this.$route.query.title_type : '';
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;
			this.$http.post({
				url: '/Prize/listPage',
				data: this.searchForm,
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			});
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.$router.replace({query: {...this.searchForm}});
		},
	}
}
</script>
