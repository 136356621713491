<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="链云" :height="250" title-class="margin-top-70" sub-title="首个医疗器械行业一站式权威平台" style="background-color: #f4f4f4;">
		<div class="wrap1100 bg-light box-shadow padding-20 rel" style="margin-top: -50px;">
			<lee-select v-model="type" style="width: 282px;" :options="searchTypeList"></lee-select>
			<lee-select v-model="searchForm.title_type" style="width: 177px;" class="margin-left" :options="[{label:'标题',value:'title'},{label:'关键字',value:'key'}]"></lee-select>
			<lee-input placeholder="输入关键词" v-model="searchForm.title" style="width: 385px" class="margin-left"/>
			<el-button type="primary" size="medium" class="vm lee-button margin-left f-lq" @click="toSearch" style="width: 186px;">
				<i class="el-icon-s-promotion"></i>查询
			</el-button>
		</div>
		<div class="wrap1100 margin-top-60 margin-bottom-30">
			<div class="cl">
				<div class="f20 z">
					<div>行业热点<span class="vm f-thin margin-left dark-2 f-14">INDUSTRY HOTSPOTS</span></div>
					<div class="base-line"></div>
				</div>
<!--				<div class="y">-->
<!--					<el-button :type="polityType === 'type' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('type')">政策内容</el-button>-->
<!--					<el-button :type="polityType === 'list' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('list')">政策解读</el-button>-->
<!--					<el-button :type="polityType === 'hot' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('hot')">行业热点</el-button>-->
<!--					<el-button size="mini" class="border-radius-0 mg-l-80" @click="$router.push({path:'/policy'})">查看更多 >></el-button>-->
<!--				</div>-->
			</div>
		</div>
		<div class="wrap1100">
			<div class="bg-light pd-20 cl mg-b-20 hand" v-for="(item,index) in allList" @click="onAllListClick(item)">
				<div class="z bd-r-w-1 bd-dark-8 bd-style-solid hide" style="width: 100px;height: 60px;">
					<div class="dark-7 f-22 lh-60">{{item.type_str}}</div>
				</div>
				<div class="z pd-l-r-30" style="width: 900px">
					<div class="f-18">{{item.title}}</div>
					<div class="mg-t-10" v-if="item.tags && item.tags.length > 0"><el-tag type="info" class="bd-radius-0 h-24 lh-24 hand c-b mg-r-10" v-for="(st,si) in item.tags">{{st}}</el-tag></div>
					<div class="f-12 mg-t-10 dark-7">{{item.create_time}}</div>
				</div>
				<div class="y lh-50 f-30 dakr-2">&gt;</div>
			</div>
		</div>
		<div class="pd-t-b-80 mg-t-50" style="background-image: url('/images/bg_2.jpg');background-repeat:  no-repeat;background-size: 100% 100%;">
			<div class="wrap1100 margin-top-60 margin-bottom-30">
				<div class="cl">
					<div class="f20 z">
						<div class="light">最新政策<span class="vm f-thin margin-left light f-14">LATEST POLICIES</span></div>
						<div class="base-line-2"></div>
					</div>
					<div class="y">
						<el-button :type="polityType === 'type' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('type')">政策内容</el-button>
						<el-button :type="polityType === 'list' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('list')">政策解读</el-button>
<!--						<el-button :type="polityType === 'hot' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('hot')">行业热点</el-button>-->
						<el-button size="mini" class="border-radius-0 mg-l-80" @click="$router.push({path:'/policy'})">查看更多 >></el-button>
					</div>
				</div>
			</div>
			<div class="wrap1100">
				<div class="bg-light pd-20 cl mg-b-20 hand" v-for="(item,index) in policyList" @click="$router.push({path:'/policy/detail/'+item.id})">
					<div class="z bd-r-w-1 bd-dark-8 bd-style-solid hide" style="width: 100px;height: 60px;">
						<div class="dark-7 f-28 lh-40">{{$moment(item.create_time).format('DD')}}</div>
						<div class="dark-7 f-14 lh-20">{{$moment(item.create_time).format('YYYY.M')}}</div>
					</div>
					<div class="z pd-l-r-30" style="width: 900px">
						<div class="f-18">{{item.title}}</div>
						<div class="mg-t-10" v-if="item.tags && item.tags.length > 0"><el-tag type="info" class="bd-radius-0 h-24 lh-24 hand c-b mg-r-10" v-for="(st,si) in item.tags">{{st}}</el-tag></div>
					</div>
					<div class="y lh-50 f-30 dakr-2">&gt;</div>
				</div>
			</div>
<!--			<div class="wrap1100">-->
<!--				<div class="cl">-->
<!--					<div class="z">-->
<!--						<div class="f20 light">最新政策<span class="vm f-thin margin-left light f-14">LATEST STANDARD</span></div>-->
<!--						<div class="base-line-2"></div>-->
<!--					</div>-->
<!--					<div class="y">-->
<!--						<el-button :type="polityType === 'type' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('type')">政策内容</el-button>-->
<!--						<el-button :type="polityType === 'list' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('list')">政策解读</el-button>-->
<!--&lt;!&ndash;						<el-button :type="polityType === 'hot' ? 'primary' : ''" size="mini" class="border-radius-0" @click="changePolityType('hot')">行业热点</el-button>&ndash;&gt;-->
<!--						<el-button size="mini" class="border-radius-0 mg-l-80" @click="$router.push({path:'/policy'})">查看更多 >></el-button>-->
<!--					</div>-->
<!--				</div>-->
<!--				-->
<!--			</div>-->
		</div>

		<div class="bg-light pd-t-b-100">
			<div class="wrap1100">
				<div class="f20">
					<div>最新行业数据<span class="vm f-thin margin-left dark-2 f16">LATEST INDUSTRY DATA</span></div>
					<div class="base-line"></div>
				</div>
				<lee-index-hydata class="mg-t-40"></lee-index-hydata>
			</div>
		</div>
		<div class="pd-t-b-140" style="background-image: url('/images/bg_1.jpg');background-repeat:  no-repeat;background-size: 100% 100%;">
			<div class="wrap1100">
				<div>
					<div class="f20 light">最新标准<span class="vm f-thin margin-left light f-14">LATEST STANDARD</span></div>
					<div class="base-line-2"></div>
				</div>
				<el-row :gutter="10" class="mg-t-30">
					<el-col :span="24 / standardType.length" v-for="(item,index) in standardType">
						<div class="bg-blue-light pd-10 h-250 rel hand" @click="$router.push({path:'/standard/list/'+item.id})">
							<div class="rel" style="z-index: 2">
								<div class="">
									<img :src="$utils.formatUrl(item.icon)" class="h-24"/>
									<span class="vm mg-l-5 f-18">{{item.name}}</span>
								</div>
								<div class="dark-3 f-12 mg-t-10 mg-r-60 h-140 hide">{{item.des}}</div>
								<el-button type="primary" size="small" round>查看更多&gt;</el-button>
							</div>
							<i class="iconfont icon-similarproduct abs" style="z-index:1;color: #E7F2FF;font-size: 140px;right:10px;bottom:10px;"></i>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="pd-t-b-140" style="background-image: url('/images/bg_2.jpg');background-repeat:  no-repeat;background-size: 100% 100%;background: #fff !important;">
			<div class="wrap1100">
				<div>
					<div class="f20">研究报告<span class="vm f-thin margin-left dark-2 f-14">RESEARCH REPORT</span></div>
					<div class="base-line"></div>
				</div>
				<el-row :gutter="20" class="mg-t-20">
					<el-col :span="12" v-for="(item,index) in reportList" class="mg-b-20">
						<div class="bg-light pd-15 cl hand" @click="$router.push({path:item.type === 'industry' ? '/industry/detail/'+item.id : '/domain/detail/'+item.id})" style="background: #f8f8f8;">
							<div class="z">
								<img :src="$utils.formatUrl(item.head_img)" style="width: 100px;height: 110px;"/>
							</div>
							<div class="z mg-l-20" style="width: 390px;">
								<div class="text-line-1">{{item.title}}</div>
								<div class="f-12 dark-5 text-line-3 mg-t-10">{{item.content}}</div>
								<div class="f-12 dark-5 text-right mg-t-20">{{$moment(item.create_time).format('YYYY-MM hh:mm')}}</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			type: 'policy',
			searchForm: {
				title_type: 'title',
				title: ''
			},
			polityType:'type',
			searchTypeList: [
				// {
				//     value: 'news',
				//     label: '新闻资讯'
				// },
				{
					value: 'policy',
					label: '政策法规'
				},
				{
					value: 'standard',
					label: '标准'
				},
				{
					value: 'yanjiu',
					label: '研究报告'
				},
				{
					value: 'Question',
					label: '问答中心'
				},
				{
					value: 'study',
					label: '教育培训'
				},
				{
					value: 'zhuanti',
					label: '会议活动'
				},
				{
					value: 'prize',
					label: '重点企业'
				},
			],
			reportList: [],
			policyList: [],
			standardType:[],
			allList:[],
			policyTypeIndex: 'medicine',
			reportType: 'medicine'
		}
	},
	mounted() {
		this.getReportList();
		this.getPolicyType();
		this.getStandardType();
		this.getAllFirst();
	},
	methods: {
		onAllListClick(e){
			if(e.type === 'zhuanti'){
				this.$router.push({path:'/zhuanti/detail',query:{id:e.id}});
			}else if(e.type === 'domain'){
				this.$router.push({path:'/domain/detail/'+e.id});
			}else if(e.type === 'policy'){
				this.$router.push({path:'/policy/detail/'+e.id});
			}else if(e.type === 'standard'){
				this.$router.push({path:'/standard/detail/'+e.id});
			}else if(e.type === 'study'){
				this.$router.push({path:'/study/detail/'+e.id});
			}
		},
		changePolityType(e){
			this.polityType = e;
			// if(e === 'hot'){
			//
			// 	this.getAllFirst();
			// }else {
				this.getPolicyType();
			// }
		},
		getAllFirst(){
			let _this = this;
			this.allList = [];
			this.$http.post({
				url: '/Type/listAll',
				data: {state: 1, akey: 'policy',atype:'type'},
				success(res) {
					let _ids = [];
					for(let i=0;i < res.length;i++){
						_ids.push(res[i].id);
					}

					_this.$http.post({
						url: '/Policy/listPage',
						data: {page: 1, pageSize: 1, state: 1, type: 'policy',orderBy:'create_time',sortBy:'desc',type_policy_id:[_ids,'in']},
						success(res) {
							if(res.list.length > 0){
								let _list = res.list[0];
								_this.allList.push({title:_list.title,type_str:'政策法规',type:'policy',id:_list.id,create_time:_list.create_time});
							}
						}
					})
				}
			})

			this.$http.post({
				url: '/Type/listAll',
				data: {state: 1,  akey: 'standard',atype:'list'},
				success(res) {
					let _ids = [];
					for(let i=0;i < res.length;i++){
						_ids.push(res[i].id);
					}

					_this.$http.post({
						url: '/Policy/listPage',
						data: {page: 1, pageSize: 1, state: 1,  type: 'standard',orderBy:'create_time',sortBy:'desc',type_policy_id:[_ids,'in']},
						success(res) {
							if(res.list.length > 0){
								let _list = res.list[0];
								_this.allList.push({title:_list.title,type_str:'标准',type:'standard',id:_list.id,create_time:_list.create_time});
							}
						}
					})
				}
			})
			this.$http.post({
				url: '/Report/listPage',
				data: {page: 1, pageSize: 1, state: 1,  type: 'domain',orderBy:'create_time',sortBy:'desc'},
				success(res) {
					if(res.list.length > 0){
						let _list = res.list[0];
						_this.allList.push({title:_list.title,type_str:'研究报告',type:'domain',id:_list.id,create_time:_list.create_time});
					}
				}
			})
			this.$http.post({
				url: '/Zhuanti/listPage',
				data: {page: 1, pageSize: 1, state: 1,orderBy:'create_time',sortBy:'desc'},
				success(res) {
					if(res.list.length > 0){
						let _list = res.list[0];
						_this.allList.push({title:_list.title,type_str:'会议',type:'zhuanti',id:_list.id,create_time:_list.create_time});
					}
				}
			})

			this.$http.post({
				url: '/Type/listAll',
				data: {state: 1, akey: 'study',atype:'list'},
				success(res) {
					let _ids = [];
					for(let i=0;i < res.length;i++){
						_ids.push(res[i].id);
					}

					_this.$http.post({
						url: '/Policy/listPage',
						data: {page: 1, pageSize: 1, state: 1, type: 'study',orderBy:'create_time',sortBy:'desc',type_policy_id:[_ids,'in']},
						success(res) {
							if(res.list.length > 0){
								let _list = res.list[0];
								_this.allList.push({title:_list.title,type_str:'教育培训',type:'study',id:_list.id,create_time:_list.create_time});
							}
						}
					})
				}
			})
		},
		toSearch() {
			// console.log(this.searchForm);
			if (this.type === 'policy') {
				this.$router.push({path: '/policy/search', query: {...this.searchForm}})
			} else if (this.type === 'standard') {
				this.$router.push({path: '/standard/search', query: {...this.searchForm}})
			} else if (this.type === 'yanjiu') {
				this.$router.push({path: '/yanjiu/search', query: {...this.searchForm}})
			} else if (this.type === 'news') {
				this.$router.push({path: '/news', query: {...this.searchForm}})
			}else if (this.type === 'study') {
				this.$router.push({path: '/study/search', query: {...this.searchForm}})
			}else if (this.type === 'zhuanti') {
				this.$router.push({path: '/zhuanti/search', query: {...this.searchForm}})
			}else if (this.type === 'prize') {
				this.$router.push({path: '/prize/search', query: {...this.searchForm}})
			} else {
				this.$router.push({path: '/qa', query: {...this.searchForm}})
			}
		},
		getReportList() {
			let _this = this;
			this.$http.post({
				url: '/Report/listPage',
				data: {page: 1, pageSize: 4, state: 1, type: 'industry'},
				success(res) {
					_this.reportList = res.list;
				}
			})
		},
		getPolicyList(ids) {
			let _this = this;
			this.$http.post({
				url: '/Policy/listPage',
				data: {page: 1, pageSize: 4, state: 1,  type: 'policy',type_policy_id:[ids,'in']},
				success(res) {
					_this.policyList = res.list;
				}
			})
		},
		getStandardType() {
			let _this = this;
			this.$http.post({
				url: '/Type/listAll',
				data: {state: 1,  akey: 'standard',atype:'list',orderBy:'sort',sortBy:'asc',limit:4},
				success(res) {
					_this.standardType = res
				}
			})
		},
		getPolicyType() {
			let _this = this;
			this.$http.post({
				url: '/Type/listAll',
				data: {state: 1,  akey: 'policy',atype:this.polityType},
				success(res) {
					let _ids = [];
					for(let i=0;i < res.length;i++){
						_ids.push(res[i].id);
					}
					_this.getPolicyList(_ids);
				}
			})
		}
	}
}
</script>
<style scoped>
.block1 {
	background: #fff;
	cursor: pointer;
	padding: 30px 0;
}

.block1:hover {
	background: #00CCEF;
}

.block1 .icon-off {
	display: unset;
}

.block1 .icon-on {
	display: none;
}

.block1:hover .icon-off {
	display: none;
}

.block1:hover .icon-on {
	display: unset;
}

.block1:hover .title, .block1:hover .sub-title {
	color: #fff !important;
}

.block1 .img {
	width: 39px;
	height: 39px;
	margin: 0 30px;
}

.block1 .title {
	color: #6C6C6C;
}

.block1 .sub-title {
	color: #9F9F9F;
}

.ad-item {
}

.ad-item .time, .ad-item .des, .ad-item .title, .ad-item .btn {
	margin-top: 10px;
}

/*.ad-item:hover{background: linear-gradient(180deg, #00CCEF, #009DD9 100%)}*/
.ad-item:hover .time, .ad-item:hover .title, .ad-item:hover .des {
	color: #fff;
}

.ad-item .img {
	height: 213px;
	width: 323px;
}

.ad-item .img img {
	height: 100%;
	width: 100%;
}

.hy-data-item {
	background: #fff;
	border-radius: 10px;
}

.hy-data-item.on, .hy-data-item:hover {
	background: #7ED321;
}

.hy-data-item.on .title, .hy-data-item:hover .title {
	color: #fff;
}

.hy-data-item.on .img, .hy-data-item:hover .img {
	background: #fff !important;
}
</style>
