export default {
    //线上测试2
    apiUrl:'//api.mdsc-chaincloud.org.cn/Api',
    uploadUrl:'//api.mdsc-chaincloud.org.cn/Api/Upload/upload',
    assetsUrl:'//api.mdsc-chaincloud.org.cn/static',

    //本地测试
    // apiUrl:'http://127.0.0.1:9503/Api',
    // uploadUrl:'http://127.0.0.1:9503/Api/Upload/upload',
    // assetsUrl:'http://api.hc-chaincloud.org.cn/static',

    //线上生产
    // apiUrl:'//api.hc-chaincloud.org.cn/Api',
    // uploadUrl:'//api.hc-chaincloud.org.cn/Api/Upload/upload',
    // assetsUrl:'//api.hc-chaincloud.org.cn/static',

    secret:'miihe!@#',

    WX_CONFIG:{
        appId: 'wx8cf80c3c647f355c'
    },
    topMenu:[
        {title:'首页',path:'/'},
        // {title:'资讯',path:'/news'},
        {title:'政策法规',path:'/policy'},
        {title:'标准',path:'/standard'},
        {title:'教育培训',path:'/study'},
        {title:'重点企业',path:'/prize'},
        {title:'行业数据',path:'/hydata/life'},
        {title:'研究报告',path:'/yanjiu'},
        {title:'会议活动',path:'/zhuanti'},
        {title:'问答中心',path:'/qa'},
        {title:'关于我们',path:'/about'},
        {title:'会员中心',path:'/user'},
        {title:'问卷调查',path:'/survey'},
        // {title:'留言板',path:'/comment'},
    ],
    userMenu:[
        {title:'个人资料',path:'/user/profile',icon:'icon-zxxz',icon2:'/images/icon/user/icon_user_9.png'},
        // {title:'我的VIP',path:'/user/vip',icon:'icon-bzxrgk',icon2:'/images/icon/user/icon_user_16.png'},
        {title:'账号安全',path:'/user/safe',icon:'icon-bzxrgk',icon2:'/images/icon/user/icon_user_10.png'},
        {title:'消费记录',path:'/user/buylog',icon:'icon-ccbx',icon2:'/images/icon/user/icon_user_11.png'},
        {title:'发票管理',path:'/user/fapiao',icon:'icon-fpgl',icon2:'/images/icon/user/icon_user_11.png'},
        {title:'我的余额',path:'/user/balance',icon:'icon-balance',icon2:'/images/icon/user/icon_user_11.png'},
        {title:'阅读记录',path:'/user/readlog',icon:'icon-ajgd',icon2:'/images/icon/user/icon_user_12.png'},
        {title:'我的评论',path:'/user/mycomment',icon:'icon-zbyt',icon2:'/images/icon/user/icon_user_13.png'},
        {title:'我的提问',path:'/qa/me',icon:'icon-cccx',icon2:'/images/icon/user/icon_user_14.png'},
        {title:'我的收藏',path:'/user/mycollection',icon:'icon-ajgd',icon2:'/images/icon/user/icon_user_15.png'},
        {title:'我的点赞',path:'/user/mygood',icon:'icon-zxhj',icon2:'/images/icon/user/icon_user_16.png'},
    ]
}
