<template>
	<lee-page bg-img="/images/bg_head_2.jpg" title="政策法规" sub-title="Policies & Regulations" search height="250" stop-search to-path="/policy/search" show-year search-time-key="push_time" title-class="margin-top-70">
		<div class="mg-t-b-40">
			<div class="wrap1100">
				<lee-tabs :list="tabList" :default-index="atype" @change="onTabChange" class="margin-auto" style="width: 500px;"></lee-tabs>
				<el-row :gutter="20" class="mg-t-30" v-if="atype === 'type'">
					<el-col :span="24 / policyType.length" v-for="(item,index) in policyType">
						<div class="pd-20 bd-radius-10 hide hand" style="background: #E7F0FA;" @click="$router.push({path:'/policy/list/'+item.id})">
							<div class="f-16">{{ item.name }}</div>
							<div class="f-12 mg-t-5" style="color:#003D8C;">{{ item.content }}</div>
							<div class="f-12 mg-t-10 dark-4">了解更多&gg;</div>
							<div class="text-right">
								<img :src="$utils.formatUrl(item.head_img)" style="height: 170px;"/>
							</div>
						</div>
					</el-col>
				</el-row>
				<div class="mg-t-30" v-else>
					<div class="bg-gray-5 pd-20 cl mg-b-20 hand" v-for="(item,index) in list" @click="$router.push({path:'/policy/detail/'+item.id})">
						<div class="z bd-r-w-1 bd-dark-8 bd-style-solid hide" style="width: 100px;height: 60px;">
							<div class="dark-7 f-28 lh-40">{{$moment(item.create_time).format('DD')}}</div>
							<div class="dark-7 f-14 lh-20">{{$moment(item.create_time).format('YYYY.M')}}</div>
						</div>
						<div class="z pd-l-r-30" style="width: 900px">
							<div class="f-18">{{item.title}}</div>
							<div class="mg-t-10" v-if="item.tags && item.tags.length > 0"><el-tag type="info" class="bd-radius-0 h-24 lh-24 hand c-b mg-r-10" v-for="(st,si) in item.tags">{{st}}</el-tag></div>
						</div>
						<div class="y lh-50 f-30 dakr-2">&gt;</div>
					</div>
					<div class="text-center margin-top-100" v-if="list.length > 0">
						<el-pagination
							:hide-on-single-page="true"
							background
							:page-size="listSearch.pageSize"
							:current-page.sync="listSearch.page"
							:total="totalCount"
							layout="prev,pager,next"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			showZhengce: true,
			showBiaozhun: true,
			atype: '',
			tabList: [
				{label: '政策法规', value: 'type', icon: 'icon-icon_xinyong_xianxing_jijin-1'},
				{label: '政策解读', value: 'list', icon: 'icon-icon_xinyong_xianxing_jijin-'},
			],
			policyType: [],
			list: [],
			listSearch: {
				page:1,
				pageSize:10,
				type_policy_id: ''
			},
			totalCount:0
		}
	},
	created() {
		this.atype = this.$route.query.mmtype ? this.$route.query.mmtype : 'type'
	},
	mounted() {
		this.getPolicyType();
	},
	methods: {
		onTabChange(i) {
			console.log(i);
			this.atype = i.value;
			this.getPolicyType();
		},
		getPolicyType() {
			let _this = this;
			this.$http.post({
				url: '/Type/listAll',
				data: {akey: 'policy', atype: this.atype, orderBy: 'sort', sortBy: 'asc', state: 1},
				success(res) {
					if (_this.atype === 'type') {
						_this.policyType = res;
					} else {
						_this.listSearch.type_policy_id = res[0].id;
						_this.getList();
					}
				}
			});
		},
		getList() {
			let _this = this;

			this.$http.post({
				url: '/Policy/listPage',
				data: {...this.listSearch, orderBy: 'create_time', sortBy: 'desc'},
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(e){
			this.listSearch.page = e;
			this.getList();
		}
	}
}
</script>
