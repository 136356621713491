<template>
	<lee-page bg-img="/images/bg_head_9.jpg" title="会议活动" sub-title="CONFERENCE" search height="250" title-class="margin-top-70" stop-search to-path="/zhuanti/search" :show-search-type="false" show-year search-time-key="push_time">
		<div class="mg-t-b-40">
			<div class="wrap1100">
<!--				<lee-tabs :list="tabList" :default-index="atype" @change="onTabChange" class="margin-auto" style="width: 500px;"></lee-tabs>-->
				<div class="text-center">
					<div style="margin:0 auto;display: inline-block;padding:0 20px;" class="bg-light cl box-shadow-bottom hide">
						<template v-for="(item,index) in tabList">
							<div style="display: inline-block;margin:0 15px;" class="text-center hy-data-item" :class="{on:chooseType && chooseType.id === item.id}">
								<span @click="onTabChange(item)"><img style="width: 16px;height: 16px;vertical-align: middle;" v-if="item.icon" :src="item.icon.indexOf('http') >= 0 ? item.icon : $config.assetsUrl + item.icon" class="mg-r-5"/>{{item.name}}</span>
							</div>
						</template>
					</div>
				</div>
<!--				<el-row class="bg-light cl box-shadow-bottom hide margin-auto" style="width: 500px;">-->
<!--					<el-col :span="24 / tabList.length" v-for="(item,index) in tabList">-->
<!--						<div class="text-center hy-data-item" :class="{on:chooseType && chooseType.id === item.id}">-->
<!--							<span @click="onTabChange(item)"><img style="width: 16px;height: 16px;vertical-align: middle;" v-if="item.icon" :src="item.icon.indexOf('http') >= 0 ? item.icon : $config.assetsUrl + item.icon" class="mg-r-5"/>{{item.name}}</span>-->
<!--						</div>-->
<!--					</el-col>-->
<!--				</el-row>-->
				<el-row :gutter="20" class="mg-t-30 pd-b-200" v-if="chooseType && chooseType.atype === 'type'">
					<el-col :span="24 / policyType.length" v-for="(item,index) in policyType">
						<div class="pd-20 bd-radius-10 hide hand" style="background: #E7F0FA;" @click="$router.push({path:'/zhuanti/list/'+item.id})">
							<div class="f-16">{{ item.name }}</div>
							<div class="f-12 mg-t-5" style="color:#003D8C;">{{ item.content }}</div>
							<div class="f-12 mg-t-10 dark-4">了解更多&gg;</div>
							<div class="text-right">
								<img :src="$utils.formatUrl(item.head_img)" style="height: 170px;"/>
							</div>
						</div>
					</el-col>
				</el-row>
				<div class="mg-t-30 pd-b-100" v-else>
					<template v-if="list.length > 0">
						<el-row :gutter="20" class="mg-t-30">
							<el-col :span="8" v-for="(item,index) in list">
								<div class="bg-light mg-b-20 hand" @click="$router.push({path:'/zhuanti/detail/'+item.id})" style="height: 395px;">
									<img :src="$utils.formatUrl(item.head_img)" style="width: 100%;height: 200px;"/>
									<div class="pd-10">
										<div class="f-18 text-line-2" style="height: 50px;">{{item.title}}</div>
										<div class="f-14 dark-4 text-line-2 mg-t-5" style="height: 40px;line-height: 20px;">{{item.des}}</div>
										<div class="f-12 dark-4 mg-t-10">
											<i class="el-icon-location-information mg-r-10 f-14 vm"></i>{{item.address}}
										</div>
										<div class="f-12 dark-4 mg-t-5">
											<i class="el-icon-time mg-r-10 f-14 vm"></i>{{$moment(item.start_time).format('YYYY年MM月DD日')}}-{{$moment(item.end_time).format('YYYY年MM月DD日')}}
										</div>
										<div class="mg-t-15 c-b-2 f-12">查看更多&gt;</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</template>
					<div class="text-center dark-4 f-18 pd-t-b-50 bg-gray-4" v-else>暂无数据</div>
					<div class="text-center mg-t-50">
						<el-pagination
							background
							:page-size="listSearch.pageSize"
							:current-page.sync="listSearch.page"
							:total="totalCount"
							layout="prev,pager,next"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			atype: '',
			tabList: [
				{label: '标准', value: 'type', icon: 'icon-icon_xinyong_xianxing_jijin-1'},
				{label: '标准宣贯', value: 'list', icon: 'icon-icon_xinyong_xianxing_jijin-'},
			],
			policyType: [],
			list: [],
			listSearch: {
				page:1,
				pageSize:10,
				type_policy_id: ''
			},
			totalCount:0,
			chooseType:null
		}
	},
	created() {
		this.atype = this.$route.query.mmtype ? this.$route.query.mmtype : 'type'
	},
	mounted() {
		this.getPolicyType();
	},
	methods: {
		onTabChange(i) {
			// console.log(i);
			this.chooseType = i;
			if(i.atype === 'list'){
				this.getList();
			}else{
				this.getSonType(i.id);
			}
			// this.getPolicyType();
		},
		getPolicyType() {
			let _this = this;
			this.$http.post({
				url: '/Type/listAll',
				data: {akey: 'zhuanti', pid:'0',orderBy: 'sort', sortBy: 'asc', state: 1},
				success(res) {
					// if (_this.atype === 'type') {
					// 	// _this.policyType = res;
					// 	_this.tabList = res;
					// 	_this.chooseType = res[0];
					// 	_this.getSonType(res[0].id);
					// } else {
					// 	_this.listSearch.type_policy_id = res[0].id;
					// 	_this.getList();
					// }
					_this.tabList = res;
					_this.chooseType = res[0];
					_this.getSonType(res[0].id);
				}
			});
		},
		getSonType(id) {
			let _this = this;
			this.$http.post({
				url: '/Type/listAll',
				data: {akey: 'zhuanti', pid:id,orderBy: 'sort', sortBy: 'asc', state: 1},
				success(res) {
					_this.policyType = res;
				}
			});
		},
		getList() {
			let _this = this;

			this.$http.post({
				url: '/Zhuanti/listPage',
				data: {...this.listSearch,type_zhuanti_id:this.chooseType.id, orderBy: 'create_time', sortBy: 'desc'},
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(e){
			this.listSearch.page = e;
			this.getList();
		}
	}
}
</script>
